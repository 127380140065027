@media (orientation: portrait) {
    .mytrainees_page {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;

        background-color: white;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .mytrainees_page > div.controlbox {
        position: sticky;
        z-index: 2;
        background-color: rgb(255,255,255,0.8);
        backdrop-filter: blur(2px);
        border-radius: 1rem;
        border-top-right-radius: 0;
        left: 5px;
        top: 0;
        width: 99%;
        height: 10%;
        display: flex;
        flex-direction:row;
        justify-content: space-around;
        align-items: center;
    }
    .mytrainees_page > div.controlbox > button.back {
        height: 45px;
        width: 15%;
        background-color: rgb(0,0,0);
        border: solid 0.1rem black;
        color: white;
        font-size: 1.5rem;
        border-radius: 1rem;
        transition: all 0.3s;
    }
    .mytrainees_page > div.controlbox > button.back:hover , button.back:active{
        background-color: white;
        color: rgb(180,0,0);
        border-color: rgb(180,0,0);
    }

    .mytrainees_page > div.controlbox > div.topic {
        width: 80%;
        height: 45px;
    }
    .mytrainees_page > div.controlbox > div.topic span {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        font-size: 2rem;
        font-weight: bold;
        color: rgb(185,55,20);
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        text-shadow: 4px 4px 20px rgba(0,0,0,0.7);
    }

    .mytrainees_page > div.trainees {
        position: absolute;
        top: 15%;
        min-height: 85%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: end;
        gap: 35px;
    }

    .mytrainees_page > div.trainees> div.trainee {
        width: 95%;
        height: 60px;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        direction: rtl;

        cursor: pointer;
        transition: all 0.3s;
    }
    .mytrainees_page > div.trainees> div.trainee > div.trainee--index {
        margin-right: 5px;
        width: 40px;
        height: 90%;
        background-color: rgb(185,55,20,0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: none;
        box-shadow: 0px 0px 10px 0px rgb(0,120,0);
    }

    .mytrainees_page > div.trainees> div.trainee > div.trainee--index > span {
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1rem;
        font-weight: bold;
        color:white;
    }

    .mytrainees_page > div.trainees> div.trainee > div.trainee--info {
        margin-right: 20px;
        width: calc(100% - 65px);
        height: 100%;
        background-color: rgb(200,205,200);
        border-top: solid 0.1rem rgb(185,55,20,0.3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .mytrainees_page > div.trainees> div.trainee > div.trainee--info > div.trainee--name {
        height: 100%;
        width: 60%;
        margin-right: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: solid 0.1rem white;
    }

    .mytrainees_page > div.trainees> div.trainee > div.trainee--info > div.trainee--name > span {
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
    }

    .mytrainees_page > div.trainees> div.trainee > div.trainee--info > div.trainee--phone {
        height: 100%;
        width: 40%;
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mytrainees_page > div.trainees> div.trainee > div.trainee--info > div.trainee--phone > span {
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 0.9rem;
        font-weight: bold;
        text-align: center;
    }
}
@media (orientation: portrait) {
    .cpanel--viewboard > .wallet {
        position: absolute;
        left: 1%;
        right: 1%;
        height: 98%;
        display: inline-block;
        /* flex-direction: column;
        justify-content: flex-start;
        align-items: center; */
    
        overflow-x: hidden;
        flex-wrap: wrap;
        overflow-y: auto;
        border-radius: 1rem;
    
        background-color: white;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        direction: rtl;
    }

    .cpanel--viewboard > .wallet > .wallet--topic {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }  
    
    .cpanel--viewboard > .wallet > .wallet--topic > span {
        font-size: 1.5rem;
    }    

    .cpanel--viewboard > .wallet > .wallet--size {
        position: absolute;
        top: 10%;
        left: 5%;
        right: 5%;
        height: 35%;
        background-color: rgb(20,20,20);
        border-radius: 2rem;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .cpanel--viewboard > .wallet > .wallet--size > .balance--topic {
        width: 100%;
        height: 20%;
        color: white;
        font-size: 1.5rem;
        padding: 10px 20px 0 0;
    }

    .cpanel--viewboard > .wallet > .wallet--size > .balance {
        width: 100%;
        height: 20%;
        color: rgb(200,200,200);
        font-size: 1.5rem;
    }

    .cpanel--viewboard > .wallet > .wallet--size > .balance > span {
        width: fit-content;
        height: 100%;
        color: white;
        font-size: 1.5rem;
        padding-left: 20px;
        margin-right: 10px;
    }

    .cpanel--viewboard > .wallet > .wallet--size > .controlbox {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .cpanel--viewboard > .wallet > .wallet--size > .controlbox > button {
        width: 50%;
        border-radius: 1rem;
        background-color: rgb(0,0,0);
        height: 50%;
        color: white;
        font-size: 1.5rem;
        border: solid rgb(0,120,0) 0.1rem;
        margin-top: calc(2vh);
    }

    .cpanel--viewboard > .wallet > .package {
        position: absolute;
        top: 45%;
        left: 0%;
        right: 0%;
        height: 55%;
        padding: 0 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .cpanel--viewboard > .wallet > .package > .packages--topic {
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .cpanel--viewboard > .wallet > .package > .packages--topic > span {
        text-align: center;
        font-size: 1.5rem;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        /* align-items: center; */
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .namebox {
        width: 80%;
        height: 20%;
        margin-right: 10%;
        background-color: rgb(160,160,160);
        border: solid 0.1rem rgb(100,100,100);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .namebox > p {
        width: 90%;
        margin: 0 5% 0 5%;
        color: white;
        font-size: 1.2rem;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .namebox > p > span {
        font-size: 1.3rem;
        color: rgb(0,120,0);
        margin: 0 10% 0 10%;
        font-weight: bold;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .datebox {
        width: 80%;
        height: 20%;
        margin-right: 10%;
        background-color: rgb(160,160,160);
        border: solid 0.1rem rgb(100,100,100);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .datebox > p {
        width: 90%;
        margin: 0 5% 0 5%;
        color: white;
        font-size: 1.2rem;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .datebox > p > span {
        font-size: 1.1rem;
        color: rgb(0,120,0);
        margin: 0 5% 0 5%;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .durationbox {
        width: 35%;
        height: 20%;
        margin-right: 10%;
        background-color: rgb(160,160,160);
        border: solid 0.1rem rgb(100,100,100);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .durationbox > p {
        width: 90%;
        margin: 0 5% 0 5%;
        color: white;
        font-size: 1.2rem;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .durationbox > p > span {
        font-size: 1.3rem;
        color: rgb(0,120,0);
        margin: 0 5% 0 5%;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .offerbox {
        width: 35%;
        height: 20%;
        margin-right: 9%;
        background-color: rgb(160,160,160);
        border: solid 0.1rem rgb(100,100,100);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .offerbox > p {
        width: 90%;
        margin: 0 5% 0 5%;
        color: white;
        font-size: 1.2rem;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .offerbox > p > span {
        font-size: 1.3rem;
        color: rgb(0,120,0);
        margin: 0 5% 0 5%;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .controlbox {
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .cpanel--viewboard > .wallet > .package > .packages--info > .controlbox > button {
        width: 80%;
        height: 90%;
        border: solid rgb(200,200,200) 0.1rem;
        border-radius: 1rem;
        background-color: rgb(185,55,20);
        font-size: 1.5rem;
        color: white;
    }

    .deposite {
        width: 100%;
        height: 100%;
        background-color: rgb(220,220,220);
        display: inline-block;
        direction: rtl;
    }

    .deposite > .wallet--size {
        position: absolute;
        top: 15%;
        left: 10%;
        right: 10%;
        height: 25%;
        background-color: rgb(20,20,20);
        border-radius: 2rem;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .deposite > .wallet--size > .balance--topic {
        width: 100%;
        height: 20%;
        color: white;
        font-size: 1.5rem;
        padding: 10px 20px 0 0;
    }

    .deposite > .wallet--size > .balance {
        width: 100%;
        height: 20%;
        color: rgb(200,200,200);
        font-size: 1.5rem;
        margin-top: 20px;
    }

    .deposite > .wallet--size > .balance > span {
        width: fit-content;
        height: 100%;
        color: white;
        font-size: 1.5rem;
        padding-left: 20px;
        margin-right: 10px;
    }

    .deposite > .deposite--input {
        position: absolute;
        top: 45%;
        left: 15%;
        right: 15%;
        height: 8%;
        border-radius: 1rem;
        background-color: rgb(200,200,200);
        color: black;
        font-size: 1.3rem;
    }

    .deposite > .deposite--input > input {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        background-color: rgb(200,200,200);
        color: black;
        font-size: 1.3rem;
        text-align: center;
        border: solid rgb(30,30,30) 0.05rem;
    }

    .deposite > .deposite--input > input:focus {
        outline: none;
    }

    .deposite > .controlbox {
        position: absolute;
        top: 60%;
        width: 90%;
        left: 5%;
        height: 15%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        direction: ltr;
    }

    .deposite > .controlbox > button {
        width: 35%;
        height: 60%;
        border-radius: 1rem;
        font-size: 1.5rem;
        color: white;
        border: solid 0.1rem white;
    }
    .deposite > .controlbox > button.back {
        background-color: rgb(120,0,0);
    }
    .deposite > .controlbox > button.send {
        background-color: rgb(0,100,0);
        opacity: 0.2;
    }

    .deposite_verify {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        direction: rtl;
    }

    .deposite_verify > .succeed {
        width: 90%;
        height: 60%;
        display: inline-block;
        background-color: rgb(220,220,220);
        border: solid 0.1rem rgb(0,100,0);
        border-radius: 2rem;
    }

    .deposite_verify > .failed {
        width: 90%;
        height: 60%;
        display: inline-block;
        background-color: rgb(220,220,220);
        border: solid 0.1rem rgb(100,0,0);
        border-radius: 2rem;
    }

    .deposite_verify > .succeed > .topic {
        position: relative;
        width: 100%;
        top: 5%;
        height: 15%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .deposite_verify > .succeed > .topic > span {
        width: 80%;
        height: 100%;
        font-size: 1.5rem;
        color: rgb(0,50,0);
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .deposite_verify > .failed > .topic {
        position: relative;
        width: 100%;
        top: 5%;
        height: 15%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .deposite_verify > .failed > .topic > span {
        width: 80%;
        height: 100%;
        font-size: 1.5rem;
        color: rgb(50,0,0);
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .deposite_verify > .succeed > .ref {
        width: 100%;
        position: relative;
        top: 15%;
        height: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .deposite_verify > .succeed > .ref > p {
        width: 90%;
        text-align: center;
        padding: 0 10px 0 0;
        font-size: 1.5rem;
        color: black;
    }

    .deposite_verify > .succeed > .ref > p > span {
        width: fit-content;
        font-size: 1.3rem;
        color: rgb(30,30,30);
    }

    .deposite_verify > .succeed > .controlbox {
        width: 100%;
        position: relative;
        top: 20%;
        height: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .deposite_verify > .succeed > .controlbox > button {
        width: 40%;
        height: 60%;
        border-radius: 1rem;
        border: solid 0.1rem rgb(0,100,0);
        background-color: rgb(0,30,0);
        color: white;
        font-size: 1.2rem;
        transition: all 0.3s;
    }

    .deposite_verify > .failed > .controlbox > button:hover , button:active {
        background-color: rgb(0,100,0);
    }

    .deposite_verify > .failed > .controlbox {
        width: 100%;
        position: relative;
        top: 10%;
        height: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .deposite_verify > .failed > .controlbox > button {
        width: 40%;
        height: 60%;
        border-radius: 1rem;
        border: solid 0.1rem rgb(100,0,0);
        background-color: rgb(30,0,0);
        color: white;
        font-size: 1.2rem;
        transition: all 0.3s;
    }

    .deposite_verify > .failed > .controlbox > button:hover , button:active {
        background-color: rgb(100,0,0);
    }
}
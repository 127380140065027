@media (orientation: portrait) {
    .gymi_workout {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;

        background-color: white;
    }

    .gymi_workout > .chatbar {
        position: absolute;
        top: 0;
        left: 20px;
        right: 0;
        height: 100%;

        background-color: white;
        transition: all 1s;
    }

    .gymi_workout > .sidebar {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        width: 60%;
        height: 100%;
        background-color: rgb(255,245,245,0.5);
        backdrop-filter: blur(10px);
        box-shadow: 0px 0px 20px rgb(0,120,0);
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        transition: all 0.5s;
        z-index: 1000;
    }

    .gymi_workout > .sidebar > div.control {
        position: absolute;
        right: 0;
        width: 20px;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .gymi_workout > .sidebar > div.control > img {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        /* box-shadow: 0 0 10px 0 rgb(0,120,0); */
        opacity: 0.7;
    }

    .gymi_workout > .sidebar > div.chatslist {
        position: absolute;
        width: calc(100% - 20px);
        height: 100%;
        left: 0;
        top: 0;
        display: inline-block;
    }

    .gymi_workout > .sidebar > div.chatslist > div.topic {
        position: absolute;
        top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .gymi_workout > .sidebar > div.chatslist > div.topic > span.t0 {
        text-align: center;
        font-size: 1.8rem;
        color: rgb(185,55,20,0.8);
        font-weight: bold;
    }

    .gymi_workout > .sidebar > div.chatslist > div.topic > span.t1 {
        text-align: center;
        font-size: 0.8rem;
        color: rgb(185,55,20,0.8);
        font-weight: bold;
    }

    .gymi_workout > .sidebar > div.chatslist > div.topic--mentor {
        position: absolute;
        top: calc(15% + 40px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .gymi_workout > .sidebar > div.chatslist > div.topic--mentor > span {
        text-align: center;
        font-size: 1rem;
        color: rgb(0,0,0);
        opacity: 0.9;
        font-weight: bold;
    }

    .gymi_workout > .sidebar > div.chatslist > div.mentor {
        position: absolute;
        top:calc(15% + 60px);
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        direction: ltr;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 2vh;
        padding-bottom: 2vh;
    }
    .gymi_workout > .sidebar > div.chatslist > div.mentor > div.chat {
        width: 100%;
        height: 50px;
        background-color: transparent;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        border-top: solid 0.1rem rgb(200,200,200);

        cursor: pointer;
        transition: all 0.3s;
    }
    .gymi_workout > .sidebar > div.chatslist > div.mentor > div.chat.invite {
        color: rgb(185,55,20);
        font-size: 3rem;
    }
    .gymi_workout > .sidebar > div.chatslist > div.mentor> div.chat.invite:hover , div.chat.invite:active {
        color: rgb(0,120,0);
    }
    .gymi_workout > .sidebar > div.chatslist > div.mentor > div.chat > p.chat--name {
        width: 100%;
        height: 100%;
        width: fit-content;
        color: rgb(50,50,50);
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 0.8rem;
        transition: all 0.3s;
    }


    .gymi_workout .mymodal > div.invite {
        width: 100%;
        height: 100%;
        display: inline-block;
    }
    .gymi_workout .mymodal > div.invite > div.topic {
        position: absolute;
        top: 10%;
        left: 20%;
        right: 20%;
        height: 10%;
        font-size: 2rem;
        border: solid 0.1rem rgb(185,55,20);
        border-radius: 0.5rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gymi_workout .mymodal > div.invite > div.topic span {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .gymi_workout .mymodal > div.invite > span.describtion {
        position: absolute;
        top: 30%;
        left: 10%;
        right: 10%;
        height: 15%;
        font-size: 1.25rem;
        color: rgb(200,200,200);
        text-align: center;
        direction: rtl;
        white-space: pre-line;
        word-wrap: break-word;
    }
    .gymi_workout .mymodal > div.invite > input.phone {
        position: absolute;
        top: 45%;
        left: 10%;
        right: 10%;
        height: 8%;
        font-size: 1.5rem;
        color: rgb(200,200,200);
        text-align: center;
        background-color: rgb(10,10,10);
        border: solid 0.1rem black;
        border-radius: 1rem;
    }
    .gymi_workout .mymodal > div.invite > input.phone:focus {
        outline: none;
    }
    .gymi_workout .mymodal > div.invite > button {
        position: absolute;
        top: 60%;
        width: 44%;
        height: 9%;
        font-size: 1.3rem;
        color: white;
        text-align: center;
        background-color: rgb(10,10,10);
        border: solid 0.1rem rgb(50,50,50);
        border-radius: 1rem;
        transition: all 0.3s;
        cursor: pointer;
    }
    .gymi_workout .mymodal > div.invite > button.send {
        right: 4%;
    }
    .gymi_workout .mymodal > div.invite > button.send:hover , button.send:active {
        border-color: rgb(0,120,0);
    }
    .gymi_workout .mymodal > div.invite > button.back {
        left: 4%;
    }
    .gymi_workout .mymodal > div.invite > button.back:hover , button.back:active {
        border-color: rgb(185,55,20);
    }
}


@media (orientation: landscape) {
        
    .cpanel--body {
        width: 100%;
        height: 100%;
        background-color: rgb(200, 200, 200);
        margin: 0;
        position: fixed;
    }

    .cpanel--body > div {
        width: 100%;
        height: 100%;
        background-color: rgb(200, 200, 200);
        margin: 0;
        position: fixed;
        display: flex;
        flex-direction: row;
    }

    .cpanel--dashboard {
        margin-top: 2.5%;
        margin-left: 2%;
        margin-right: 5%;
        width: 10%;
        height: 90%;
        border-radius: 25px;
        background-color: white;
        border-right-style:solid;
        border-color: black;
        border-width: 1px;

        display: flex;
        flex-direction: column;
        position: relative;
        left: 0;

        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
        transition: left 1000ms;
    }

    .cpanel--viewboard {
        width: 80%;
        height: 92%;
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        left: 0;
        overflow-y: auto;

        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
        transition: left 1000ms;
    }

    .cpanel--dashboard > a.logo {
        width: 100%;
        height: 20%;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
    }

    .cpanel--dashboard > a.logo img {
        /* height: 100%; */
        min-width: 40%;
        transition: all 0.3s;
        cursor: pointer;
        margin-top: 10%;
    }

    .cpanel--dashboard > a.logo img:hover {
        filter: brightness(40%);
    }

    .cpanel--dashboard > div.task  {
        width: 100%;
        height: 16%;

        border-top-style:solid;
        border-color: black;
        border-width: 1px;
        justify-content: center;
        align-items: center;
        display: flex;
        transition: color 0.2s;
        transition: background-color 0.2s;
    }

    .cpanel--dashboard > div.task.mealmaker  {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    .cpanel--dashboard > div.task:hover  {
        cursor: pointer;
    }

    .cpanel--dashboard > div.task > p  {
        font-size: calc(min(2.5vh , 4vw));
        text-align: center;
        display: flex;
        transition: color 0.2s;
    }

    .cpanel--dashboard > div.cover  {
        width: 100%;
        height: 35%;
        display: flex;
        align-items:end;
        justify-content: flex-end;
        background-image: linear-gradient(to top , rgb(0, 0, 0) , rgb(255, 255, 255));
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
    }

    .cpanel--dashboard > div.cover img {
        filter: brightness(40%) contrast(110%);
        max-width: 100%;
        min-width: 100%;
        max-height: 60%;
        /* box-shadow: 0px 10px 5px #000000; */
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
    }

     .chatbox {
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        direction: rtl;
        white-space: normal;
        word-wrap: break-word;
        /* border-radius: 15px; */
        overflow-y: hidden;
    }

    .chatbox > div.static--chats > div.chatbox--input {
        width: 100%;
        height: auto;
        max-height: 210px;
        min-height: 60px;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        background-color: white;
    }

    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        max-height: 215px;
        min-height: 55px;
        overflow: hidden;
        background-color: white;
    }
    
    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin textarea {
        position: absolute;
        top: 0;
        bottom: 10px;
        left: 3%;
        right: 15%;
        height: auto;
        max-height: 200px;
        min-height: 45px;
        overflow: hidden;
        /* position: absolute; */
        /* bottom: 0;
        left: 0; */
        /* top: 0; */
        padding: 15px;
        box-sizing:border-box;
        font-size: 1rem;
        background-color: rgb(230,230,230);
        color: black;
        border-radius: 1.5rem;
        border: none;
        direction: ltr;
    }
    
    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin textarea:focus {
        outline: none;
    }
    
    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin button {
        position: absolute;
        top: 0;
        right: 1%;
        left: 87%;
        height: 45px;
        background-color: rgb(220,220,220);
        border-radius: 3rem;
        color: black;
        font-size: 1.3rem;
        border: none;
        opacity: 0.2;
    
        transition: all 0.3s;
    }

    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin img {
        height: 60%;
        max-width: 60%;
    }
        

    .chatbox > div.controlbox {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .chatbox > div.controlbox button {
        width: 25%;
        height: 70%;
        border: none;
        border-radius: 1rem;
        background-color: rgb(220,220,220);
        opacity: 0.8;

        transition: all 0.3s;
    }

    .chatbox > div.controlbox button.controlbox:active , button.controlbox:hover {
        opacity: 1;
    }


     .chatbox > div.static--chats > div.streambox {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: right;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }

     .chatbox > div.static--chats > div.streambox > div.textbox {
        margin:10px;
        padding: 5px;
        position: relative;
        width: 80%;
        min-height: 5%;
        height: fit-content;
        display: flex;
        justify-content: right;
        align-content: center;
        align-items: center;

        transition: all 0.2s;
    }

     .chatbox > div.static--chats > div.streambox > div.textbox span {
        margin: 5px;
        position: relative;
        color: black;
        height: fit-content;
        min-width: 5%;
        max-width: 100%;

        white-space: pre-line;
        word-wrap: break-word;
        font-size: 2vh;
        line-height: 3vh;
        min-height: 5vh;
        align-content: center;
    }

     .chatbox > div.static--chats {
        overflow-y: auto;
        height: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;

        transition: all 0.2s;
    }
     .chatbox > div.static--chats > :first-child {
        margin-top: auto !important;
    }

     .chatbox > div.static--chats > div.leftchat {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: left;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
     .chatbox > div.static--chats > div.leftchat > div.textbox {
        margin:10px;
        padding: 10px;
        position: relative;
        min-width: 5%;
        max-width: 80%;
        min-height: 5%;
        height: fit-content;
        display: flex;
        justify-content: right;
        border-radius: 25px;
        background-color: rgb(230,230,230);

        transition: all 0.2s;
    }
     .chatbox > div.static--chats > div.leftchat span {
        margin: 5px;
        position: relative;
        color: black;
        height: fit-content;

        white-space: pre-line;
        word-wrap: break-word;
        font-size: 2vh;
        line-height: 3vh;
        min-height: 5vh;
        text-align: right;
    }

     .chatbox > div.static--chats > div.rightchat {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: right;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
     .chatbox > div.static--chats > div.rightchat > div.textbox {
        margin:10px;
        padding: 10px;
        position: relative;
        width: 80%;
        min-height: 5%;
        height: fit-content;
        display: flex;
        justify-content: right;
        align-content: center;
        align-items: center;
        

        transition: all 0.2s;
    }
     .chatbox > div.static--chats > div.rightchat > div.textbox span {
        margin: 0 5px 0 5px;
        position: relative;
        color: #000000;
        height: fit-content;
        min-width: 5%;
        max-width: 100%;
        white-space: pre-line;
        word-wrap: break-word;
        font-size: 2vh;
        line-height: 3vh;
        min-height: 5vh;
        align-content: center;
    }

    .chatbox > div.static--chats > div.rightchat > div.logobox {
        width: 15%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .chatbox > div.static--chats > div.rightchat > div.logobox .logo {
        height: 30px;
        width: 30px;
        border-radius: 100px;
        border: solid 0.1rem rgb(240,240,240);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .chatbox > div.static--chats > div.rightchat > div.logobox .logo img {
        width: 50%;
        height: 50%;
    }

     .chatbox > div.endconv--modal {
        left: 0;
        top: 0;
        display: none;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

     .chatbox > div.endconv--modal > div.modal--endverify {
        width: 80%;
        height: 60%;
        position: relative;
        background-color: rgb(0, 0, 0);
        border: solid 2px rgb(185, 55, 20);
        /* display: flex; */
        /* justify-content: center; */
        border-radius: 15px;
        opacity: 0;
        overflow: none;
        transition: all 0.3s;
    }

     .chatbox > div.endconv--modal > div.modal--endverify > div.topic {
        margin-top: 20%;
        margin-right: 20%;
        width: 60%;
        height: 20%;
        position: absolute;
        color: white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 2.5vh;
    }

     .chatbox > div.endconv--modal > div.modal--endverify > input.name {
        margin-top: 10%;
        margin-right: 25%;
        width: 50%;
        height: 10%;
        position: absolute;
        color: white;
        background-color: black;
        border: solid 2px white;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 2vh;
    }

     .chatbox > div.endconv--modal > div.modal--endverify > button.agree {
        margin-top: 30%;
        margin-right: 20%;
        width: 25%;
        height: 15%;
        position: absolute;
        background-color: rgb(0,0,0);
        color: rgb(0,180,0);
        text-align: center;
        font-size: 2vh;
        border: solid 2px white;
        border-radius: 10px;
    }

     .chatbox > div.endconv--modal > div.modal--endverify > button.disagree {
        margin-top: 30%;
        margin-right: 55%;
        width: 25%;
        height: 15%;
        position: absolute;
        background-color: rgb(0,0,0);
        color: rgb(180,0,0);
        text-align: center;
        font-size: 2vh;
        border: solid 2px white;
        border-radius: 10px;
    }

    


     .chatbox > div.delconv--modal {
        left: 0;
        top: 0;
        display: none;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

     .chatbox > div.delconv--modal > div.modal--delverify {
        width: 80%;
        height: 60%;
        position: relative;
        background-color: rgb(0, 0, 0);
        border: solid 2px rgb(185, 55, 20);
        /* display: flex; */
        /* justify-content: center; */
        border-radius: 15px;
        opacity: 0;
        overflow: none;
        transition: all 0.3s;
    }

     .chatbox > div.delconv--modal > div.modal--delverify > div.topic {
        margin-top: 10%;
        margin-right: 20%;
        width: 60%;
        height: 20%;
        position: relative;
        color: white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 2vh;
    }

     .chatbox > div.delconv--modal > div.modal--delverify > button.agree {
        margin-top: 10%;
        margin-right: 20%;
        width: 25%;
        height: 15%;
        position: relative;
        background-color: rgb(0,0,0);
        color: rgb(0,180,0);
        text-align: center;
        font-size: 2vh;
        border: solid 2px white;
        border-radius: 10px;
    }

     .chatbox > div.delconv--modal > div.modal--delverify > button.disagree {
        margin-top: 10%;
        margin-right: 10%;
        width: 25%;
        height: 15%;
        position: relative;
        background-color: rgb(0,0,0);
        color: rgb(180,0,0);
        text-align: center;
        font-size: 2vh;
        border: solid 2px white;
        border-radius: 10px;
    }







    .cpanel--viewboard > .gymi {
        padding-bottom: 10px;
        margin-left: 3%;
        width: 94%;
        height: 94%;
        display: flex;
        flex-direction: row;
        justify-content: left;

        border-style: solid;
        border-width: 5px;
        border-color: rgb(185, 55, 20);
        border-radius: 15px;
        overflow-x: hidden;
        flex-wrap: wrap;
        overflow-y: auto;

        background-color: rgb(40, 40, 40);
    }

    .cpanel--viewboard > .gymi > button.mode {
        width: 50%;
        height: 100%;
        font-size: 4vh;
        background-color: black;
        color: rgb(255, 255, 255);
        border-radius: 15px;
        border: none;
        white-space: pre-line;

        transition: all 0.5s;
    }

    .cpanel--viewboard > .gymi > button.mode:hover {
        color: rgb(185, 55, 20);
        /* border-top: solid 1px rgb(0,60,0);
        border-bottom: solid 1px rgb(0,60,0); */
    }

    .cpanel--viewboard > .gymi > button.mode.diet {
        border-top-right-radius: 200px;
        border-bottom-right-radius: 200px;
        border-right: solid 2px rgb(0,60,0);
    }

    .cpanel--viewboard > .gymi > button.mode.workout {
        border-top-left-radius: 200px;
        border-bottom-left-radius: 200px;
        border-left: solid 2px rgb(0,60,0);
    }

    .cpanel--viewboard > .gymi > div.chat.done {
        margin-top: 5%;
        margin-left: 4%;
        width: 28%;
        height: 20%;
        background-color: black;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        border-width: 2px;
        border-color: rgb(0, 150, 0);
        border-style: solid;
        font-size: 3vh;
        /* flex-wrap: wrap; */

        cursor: pointer;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.chat.ongoing {
        margin-top: 5%;
        margin-left: 4%;
        width: 28%;
        height: 20%;
        background-color: black;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        border-width: 2px;
        border-color: rgb(200, 180, 0);
        border-style: solid;
        font-size: 3vh;
        /* flex-wrap: wrap; */

        cursor: pointer;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.chat img {
        /* margin-top: 10%; */
        height: 40%;
        object-fit: cover;
        /* position:absolute; */
        opacity: 0.1;
        border-radius: 15px;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.chat > p.chat--name {
        width: 100%;
        height: 30%;
        width: fit-content;
        color: white;
        /* font-size: 3vh; */
        text-align: center;
        border-radius: 15px;
        margin: 1%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .cpanel--viewboard > .gymi > div.addchat {
        margin-top: 5%;
        margin-left: 4%;
        width: 28%;
        height: 20%;
        background-color: black;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        border-width: 2px;
        border-color: #000000;
        border-style: solid;
        font-size: 7vh;
        /* flex-wrap: wrap; */
        color: rgb(0, 200, 0);

        cursor: pointer;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.addchat:hover {
        /* background-color: rgb(0 , 200 , 0); */
        border-color: rgb(185, 55, 20);
        /* flex-wrap: wrap; */
        color: rgb(185, 55, 20);

    }

    .cpanel--viewboard > .gymi > div.chat--modal {
        left: 17%;
        top: 0;
        display: none;
        visibility: hidden;
        width: 83%;
        height: 100%;
        z-index: 10;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.chat--modal > button.back {
        width: 90%;
        height: 6%;
        border-radius: 15px;
        background-color: black;
        color: white;
        font-size: 2rem;
        border: solid 0.1rem rgb(60,60,60);
        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.chat--modal > button.back:hover , button.back:active {
        border-color: white;
        color: rgb(185, 55, 20);
    }

    .cpanel--viewboard > .gymi > div.chat--modal > div.modal--chatbox {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        border-radius: 15px;
        opacity: 0;
        overflow: none;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.addchat--modal {
        left: 17%;
        top: 0;
        display: none;
        visibility: hidden;
        width: 83%;
        height: 100%;
        z-index: 10;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.addchat--modal > div.modal--addchatbox {
        width: 70%;
        height: 70%;
        background-color: rgb(0, 0, 0);
        color: black;
        position: relative;
        border-radius: 15px;
        opacity: 0;
        overflow: auto;
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 15px;
        border-color: rgb(185, 55, 20);
        border-width: 2px;
        border-style: solid;
    }

    .cpanel--viewboard > .gymi > div.addchat--modal > div.modal--addchatbox button{
        width: 30%;
        height: 10%;
        background-color: rgb(0, 0, 0);
        color: white;
        font-size: 3vh;
        margin-left: 35%;
        margin-top: 5%;
        border-radius: 10px;
        border-color: rgb(185, 55, 20);
        border-width: 2px;
        border-style: solid;

        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.addchat--modal > div.modal--addchatbox input{
        width: 60%;
        height: 10%;
        font-size: 2vh;
        background-color: rgb(250, 230, 210);
        color: black;
        margin-left: 20%;
        border-radius: 15px;
        text-align: center;
        border-color: black;
        border-width: 2px;
        border-style: solid;

        transition: all 0.3s;
    }

    .cpanel--viewboard > .gymi > div.addchat--modal > div.modal--addchatbox input:focus{
        outline: none;
        border-color: white;
    }

    .cpanel--viewboard > .gymi > div.addchat--modal > div.modal--addchatbox button:hover {
        color: rgb(0 , 150 , 0);
        border-color: rgb(0 , 150 , 0);
    }


    .cpanel--viewboard > .diets {
        padding-bottom: 10px;
        margin-left: 3%;
        width: 94%;
        height: 94%;
        display: flex;
        flex-direction: row;
        justify-content: left;

        border-style: solid;
        border-width: 5px;
        border-color: rgb(185, 55, 20);
        border-radius: 15px;
        overflow-x: hidden;
        flex-wrap: wrap;
        overflow-y: auto;

        background-color: rgb(40, 40, 40);
    }

    .cpanel--viewboard > .diets > div.diet {
        margin-top: 5%;
        margin-left: 4%;
        width: 28%;
        height: 20%;
        background-color: black;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        border-width: 2px;
        border-color: #000000;
        border-style: solid;
        font-size: 3vh;
        /* flex-wrap: wrap; */

        cursor: pointer;
        transition: all 0.3s;
    }


    .cpanel--viewboard > .diets > div.diet img {
        width: 100%;
        max-height: 70%;
        min-height: 60%;
        object-fit: cover;
        /* position:absolute; */
        opacity: 0.2;
        border-radius: 15px;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.diet > p.diet--name {
        width: 100%;
        max-height:28%;
        min-height: 28%;
        width: fit-content;
        color: white;
        /* font-size: 3vh; */
        text-align: center;
        border-radius: 15px;
        margin: 1%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .cpanel--viewboard > .diets > div.adddiet {
        margin-top: 5%;
        margin-left: 4%;
        width: 28%;
        height: 20%;
        background-color: black;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        border-width: 2px;
        border-color: #000000;
        border-style: solid;
        font-size: 7vh;
        /* flex-wrap: wrap; */
        color: rgb(0, 200, 0);

        cursor: pointer;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.adddiet:hover {
        /* background-color: rgb(0 , 200 , 0); */
        border-color: rgb(185, 55, 20);
        /* flex-wrap: wrap; */
        color: rgb(185, 55, 20);

    }

    .cpanel--viewboard > .diets > div.diet--modal {
        left: 17%;
        top: 0;
        display: none;
        visibility: hidden;
        width: 83%;
        height: 100%;
        z-index: 10;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.diet--modal > div.modal--dietbox {
        width: 80%;
        height: 80%;
        background-color: rgb(30,30,30);
        color: black;
        position: relative;
        border-radius: 15px;
        opacity: 0;
        overflow: auto;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.diet--modal > div.share {
        width: 100%;
        font-size: 2rem;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        direction: rtl;
    }

    .cpanel--viewboard > .diets > div.diet--modal > div.share button {
        width: 30%;
        height: 90%;
        background-color: rgb(0, 0, 0);
        color: rgb(255, 255, 255);
        font-size: 3vh;
        border: solid white 0.1rem;
        border-radius: 1rem;

        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.diet--modal > div.share button.share--button:hover,button.share--button:active {
        border-color: rgb(0,60,0);
    }
    .cpanel--viewboard > .diets > div.diet--modal > div.share button.delete--button:hover,button.delete--button:active {
        color: rgb(150, 0, 0);
        border-color: rgb(60,0,0);
    }
    .cpanel--viewboard > .diets > div.diet--modal > div.share button.back--button:hover,button.back--button:active {
        color: rgb(185, 55, 20);
        border-color: rgb(30,0,0);
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal {
        left: 17%;
        top: 0;
        display: none;
        visibility: hidden;
        width: 83%;
        height: 100%;
        z-index: 10;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox {
        width: 60%;
        height: 80%;
        background-color: #000000;
        border: solid 2px rgb(0 , 60 , 0);
        border-radius: 20px;
        display: flex;
        opacity: 0;

        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button {
        width: 100%;
        height: 50%;
        font-size: 4vh;
        color: white;
        background-color: #000000;
        border: none;

        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button:hover {
        color: rgb(185, 55, 20);
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button.qrcode {
       border-bottom: solid 1px rgb(185, 55, 20);
       border-top-left-radius: 20px;
       border-top-right-radius: 20px;
    }
    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button.link {
        border-top: solid 1px rgb(185, 55, 20);
        border-bottom: solid 1px rgb(185, 55, 20);
     }
     .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button.pdfdownload {
        border-top: solid 1px rgb(185, 55, 20);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
     }

     .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > .Qr {
        width: 50%;
        height: 50%;
        border: solid 1px rgb(185, 55, 20);
        border-radius: 20px;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > .back {
        width: 30%;
        height: 10%;
        margin-top: 10%;
        font-size: 3vh;
        border: solid 2px white;
        border-radius: 15px;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        direction: rtl;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .topic {
        width: 100%;
        height: 10%;
        font-size: 3vh;
        color: white;
        text-align: center;
        margin-top: 10%;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .link {
        width: 100%;
        height: 10%;
        font-size: 2vh;
        color: rgb(185, 55, 20);
        text-align: center;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .phoneTopic {
        width: 100%;
        height: 10%;
        font-size: 2.5vh;
        color: white;
        text-align: center;
        margin-top: 10%;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .phone {
        width: 60%;
        height: 8%;
        font-size: 2vh;
        color: black;
        text-align: center;
        border: 2px solid rgb(185, 55, 20);
        border-radius: 20px;
        margin-right: 20%;
        margin-left: 20%;
        margin-top: -3%;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .send {
        width: 30%;
        height: 8%;
        font-size: 2vh;
        color: white;
        background-color: black;
        border: solid 1px white;
        text-align: center;
        border-radius: 15px;
        margin-right: 35%;
        margin-top: 2%;

        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .send:hover {
        color: rgb(0 , 150 , 0);
        border-color: rgb(0 , 150 , 0);
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .back {
        width: 100%;
        height: 8%;
        font-size: 2vh;
        color: white;
        background-color: black;
        border: solid 1px rgb(0 , 60 , 0);
        text-align: center;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        margin-top: auto;

        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .back:hover {
        color: rgb(185, 55, 20);
        border-color: rgb(185, 55, 20);
    }

    .cpanel--viewboard > .diets > div.deleteDiet--modal {
        left: 17%;
        top: 0;
        display: none;
        visibility: hidden;
        width: 83%;
        height: 100%;
        z-index: 10;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    .cpanel--viewboard > .diets > div.deleteDiet--modal > div.modal--deleteDietbox {
        width: 60%;
        height: 80%;
        background-color: #000000;
        border: solid 2px rgb(0 , 60 , 0);
        border-radius: 20px;
        display: flex;
        opacity: 0;

        transition: all 0.3s;
    }




    .cpanel--viewboard > .commingsoon {
        background-color: black;
        color: white;
        width: 40%;
        height: 20%;
        position: absolute;
        left: 30%;
        top: 40%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .cpanel--viewboard > .commingsoon span {
        font-size: 4vh;
        text-align: center;
        width: 100%;
        direction: rtl;

    }

    .cpanel--viewboard > .profile {
        width: 50%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
    }
}
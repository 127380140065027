@font-face {
  font-family: main;
  src: url("../../files/Mizan.otf");
  unicode-range: U+0-29, U+40-10FFFF;
}

@font-face {
  font-family: main;
  src: url("../../files/MizanBold.otf");
  unicode-range: U+0-29, U+40-10FFFF;
  font-weight: bold;
}

@font-face {
  font-family: main;
  src: url("../../files/B-Nazanin.ttf");
  unicode-range: U+30-39;
}

@font-face {
  font-family: main;
  src: url("../../files/B-Nazanin-Bold.ttf");
  unicode-range: U+30-39;
  font-weight: bold;
}

@font-face {
  font-family: main;
  src: url("../../files/FiraSans-Regular.ttf");
  unicode-range: U+00-7F;
}

@font-face {
  font-family: main;
  src: url("../../files/FiraSans-Bold.ttf");
  unicode-range: U+00-7F;
  font-weight: bold;
}

/* @font-face {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  unicode-range: U+0020-007A;
} */

* {
  font-family: main;
  margin: 0;
  touch-action: manipulation;
  user-select: none;
}

body {
  margin: 0;
  /* width: 100%;
  height: 100%; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
  user-select: none;
}

div {
  touch-action: manipulation;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

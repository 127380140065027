
@media (orientation: portrait) {
    .t_userinterface {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 210px;
        display: flex;
        background-color: rgba(0, 0, 0, 0.99); /* Dark background with transparency */
        backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
    }

    .t_userinterface > div.image {
        width: 55%;
        height: 210px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-right: solid 1px rgb(100,100,100);
    }

    .t_userinterface > div.image > img {
        width: 150px;
        height: 150px;
        border-radius: 1rem;
        /* border: solid 0.1rem rgb(100,100,100); */
        box-shadow: 0 4px 20px white; /* Box shadow */
        /* border-top-left-radius: 1rem; */
    }

    .t_userinterface > div.image > span {
        font-size: 0.6rem;
        color: white;
    }

    .t_userinterface > div.info {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .t_userinterface > div.info > span.username {
        margin-top: 20px;
        width: 100%;
        height: 10%;
        font-size: 1.1rem;
        font-weight: bold;
        color: rgb(200,100,100);
        text-align: center;
        
    }

    .t_userinterface > div.info > span.name {
        margin-top: 40px;
        margin-right: 10px;
        width: 100%;
        height: 10%;
        font-size: 0.9rem;
        color: rgb(200,200,200);
        text-align: right;
    }

    .t_userinterface > div.info > p.trainee {
        margin-top: 10px;
        margin-right: 10px;
        width: 100%;
        height: 10%;
        font-size: 0.9rem;
        color: rgb(230,250,230);
        text-align: right;
        direction: rtl;
    }

    .t_userinterface > div.info > p.plan {
        margin-top: 10px;
        margin-right: 10px;
        width: 100%;
        height: 10%;
        font-size: 0.9rem;
        color: rgb(230,250,230);
        text-align: right;
        direction: rtl;
    }
}
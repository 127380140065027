@media (orientation: landscape) {
    .signup {
        width: 100%;
        height: 100%;
        background-color: rgb(50,50,50);
        margin: 0;
        position: absolute;
        overflow: hidden;
        display: flex;
    }
    
    .signup input:focus {
        outline: none;
    }
    
    .signup > div.form {
        top: 15%;
        left: 0%;
        width: 100%;
        height: 85%;
        position: relative;
        display: inline-block;
        background-color: rgb(10, 10, 10);
        direction: rtl;
    }

    .signup > div.form > div.form--topic {
        width: 50%;
        height: 15%;
        top: 5%;
        left: 25%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: solid 2px rgb(185,55,20);
        border-radius: 10px;
    }

    .signup > div.form > div.form--topic > span.signup--topic {
        font-size: 5vh;
        width: 100%;
        color: white;
        text-align: center;
    }

    .signup > div.form input {
        background-color: rgb(40,40,40);
        border: solid 0.1rem rgb(100,100,100);
        color: rgb(200,200,200);
        font-size: 3vh;
        border-radius: 5px;
        height: 8%;
        text-align: center;
        position: absolute;
    }

    .signup > div.form input.username {
        left: 20%;
        right: 20%;
        top:33%;
        direction: ltr;
    }

    .signup > div.form input.activation_code {
        left: 20%;
        right: 20%;
        top:23%;
        border: solid 0.05rem rgb(220,220,220);
    }

    .signup > div.form input.firstname {
        left: 51.25%;
        right: 20%;
        top:42%;
    }

    .signup > div.form input.lastname {
        left: 20%;
        right: 51.25%;
        top: 42%;
    }

    .signup > div.form input.phone {
        left: 20%;
        right: 51.25%;
        top: 51%;
        direction: ltr;
    }

    .signup > div.form input.email {
        left: 51.25%;
        right: 20%;
        top:51%;
        direction: ltr;
    }

    .signup > div.form input.password {
        left: 20%;
        right: 20%;
        top:60%;
        direction: ltr;
    }

    .signup > div.form input.password--verify {
        left: 20%;
        right: 20%;
        top:69%;
        direction: ltr;
    }


    .signup > div.form button.signup--button {
        width: 50%;
        height: 10%;
        background-color: rgb(10,10,10);
        color: white;
        border-color: rgb(0,60,0);
        border-style: solid;
        border-width: 2px;
        position: absolute;
        top:80%;
        right: 25%;
        font-size: 4vh;
        border-radius: 10px;

        transition: all 0.3s;
    }

    .signup > div.form button.signup--button:hover {
        background-color: black;
        border-color: rgb(0,180,0);
    }

    .signup > div.form > a.login {
        width: 40%;
        height: fit-content;
        font-size: 2vh;
        background: none;
        /* background-color: black; */
        color: rgb(250, 230, 210);
        position: absolute;
        top: 93%;
        text-align: center;
        right: 30%;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;

        transition: all 0.3s;
    }

    .signup > div.form > a.login:hover {
        font-size: 1.6vh;
        color: rgb(185,55,20);
    }

    .signup > div.form > span.log--error {
        width: 80%;
        height: fit-content;
        font-size: 3vh;
        background: none;
        position: absolute;
        top: 94%;
        text-align: center;
        right: 10%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .signup > div.form.mode {
        display: flex;
        flex-direction: row;
        border-radius: 20px;
    }

    .signup > div.form.mode > button.mode {
        width: 50%;
        height: 100%;
        background-color: black;
        border: 2px solid rgb(0,60,0);
        color: white;
        align-items: center;
        text-align: center;
        font-size: 4vh;
        position: initial;
        border-top: none;
        border-bottom: none;

        transition: all 0.3s;
    }
    .signup > div.form.mode > button.mode.trainee {
        border-radius: 250px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: none;
    }
    .signup > div.form.mode > button.mode.coach {
        border-radius: 250px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
    }
    .signup > div.form.mode > button.mode:hover {
        color: rgb(185,55,20);
        border-color: rgb(185,55,20);
    }

}

@media (orientation: portrait) {

    .tpanel--body {
        width: 100%;
        height: 100%;
        background-color: rgb(240,245,240);
        margin: 0;
        position: fixed;
    }
    
    .tpanel--body > div {
        width: 100%;
        height: 100%;
        background-color: rgb(240,245,240);
        margin: 0;
        position: fixed;
        display: flex;
        flex-direction: column;
    }
    
    .tpanel--dashboard {
        margin-top: 2%;
        margin-left: 2%;
        width: 96%;
        height: 8%;
        background-color: white;
        border-bottom-style:solid;
        border-color: black;
        border-width: 1px;
        border-radius: 15px;
    
        display: flex;
        flex-direction: row;
        position: relative;
        left: 0;
    
        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
        transition: left 1000ms;
    }
    
    .tpanel--viewboard {
        width: 100%;
        height: 100%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        left: 0;
        overflow-y: auto;
    
        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
        transition: left 1000ms;
    }
    
    .tpanel--dashboard > a.logo {
        width: 25%;
        height: 100%;
    
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
    }
    
    .tpanel--dashboard > a.logo img {
        width: 25px;
        height: 25px;
    }
    
    .tpanel--dashboard > a.logo img:hover , img:active {
        filter: brightness(40%);
    
    }
    
    .tpanel--dashboard > div.task  {
        width: 25%;
        height: 100%;
    
        border-right-style:solid;
        border-color: black;
        border-width: 1px;
        justify-content: center;
        align-items: center;
        display: flex;
        transition: color 0.2s;
        transition: background-color 0.2s;
        mix-blend-mode: multiply;
    }

    .tpanel--dashboard > div.task.profile  {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }
    
    .tpanel--dashboard > div.task:hover , div.task:active  {
        cursor: pointer;
    }
    
    .tpanel--dashboard > div.task > img  {
        width: 20px;
        height: 20px;
        opacity: 0.9;
        /* color: orange; */
        /* background-color: orange; */
    }

    .tpanel--dashboard > div.task.meals > img  {
        margin-top: 5px;
        /* color: orange; */
        /* background-color: orange; */
    }
    
    /* .tpanel--dashboard > div.cover  {
       width: 25%;
       height: 100%;
       display: flex;
       align-items:end;
       justify-content: flex-end;
       background-image: linear-gradient(to left , rgb(0, 0, 0) , rgb(255, 255, 255));
       border-top-right-radius: 15px;
       border-bottom-right-radius: 15px;
    }
    
    .tpanel--dashboard > div.cover img {
        filter: brightness(40%) contrast(110%);
        max-width: 60%;
        max-height: 100%;
        box-shadow: 0px 0px 10px  #000000;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    } */
    
    .tpanel--viewboard > .t_plans {
        position: absolute;
        left: 1%;
        right: 1%;
        height: 98%;
        display: flex;
        flex-direction: row;
        justify-content: left;
    
        /* border-style: solid;
        border-width: 1vw;
        border-color: rgb(185, 55, 20); */
        border-radius: 15px;
        overflow-x: hidden;
        flex-wrap: wrap;
        overflow-y: auto;
    
        background-color: rgb(250,250,250);
    }

    .tpanel--viewboard > .t_plans > span.topic {
        position: absolute;
        top: calc(10% - 20px);
        left: 10%;
        right: 10%;
        text-align: center;
        font-size: 2.5rem;
        font-weight: bold;
        color: rgb(185,55,20,0.7);
    }

    .tpanel--viewboard > .t_plans > div.mode {
        position: absolute;
        transition: all 0.5s;
        display: inline-block;
        cursor: pointer;
    }

    .tpanel--viewboard > .t_plans > div.mode img {
        position: absolute;
        left: 5%;
        top: 5%;
        width: 90%;
        height: 90%;
        opacity: 0.1;
    }

    .tpanel--viewboard > .t_plans > div.mode span {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        
    }

    .tpanel--viewboard > .t_plans > div.mode.diet {
        left: 10%;
        width: 100px;
        height: 100px;
        border-radius: 1rem;
        /* border: solid 0.1rem black; */
        background-color: rgb(240,230,230);
        color: rgb(185,55,20,0.9);
        box-shadow: 0px 2px 20px 2px rgb(150,150,250);
        top: 35%;
    }

    .tpanel--viewboard > .t_plans > div.mode.workout {
        top: 35%;
        right: 10%;
        width: 100px;
        height: 100px;
        border-radius: 1rem;
        background-color: rgb(240,230,230);
        color: rgb(185,55,20,0.9);
        box-shadow: 0px 2px 20px 2px rgb(150,150,250);
        /* border: solid 0.1rem black; */
    }

    .tpanel--viewboard > .t_plans > div.mode.diet:hover , div.mode.diet:active {
        border-color: black;
        background-color: rgb(0,0,0);
        color: white;
    }
    .tpanel--viewboard > .t_plans > div.mode.workout:hover , div.mode.workout:active {
        border-color: black;
        background-color: rgb(0,0,0);
        color: white;
    }



    /*  */
    
   .commingsoon {
        background-color: black;
        color: white;
        width: 40%;
        height: 20%;
        position: absolute;
        left: 30%;
        top: 40%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .commingsoon span {
        font-size: 4vh;
        text-align: center;
        width: 100%;
        direction: rtl;
    
    }



}
@media (orientation: portrait) {
    .login {
        width: 100%;
        height: 100%;
        background-color: rgb(245,250,245);
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        display: flex;
    }
    
    .login input:focus {
        outline: none;
    }
    
    .login > div.form {
        position: absolute;
        top: 80px;
        left: 0%;
        width: 100%;
        height: 90%;
        display: inline-block;
        background-color: rgb(245,250,245);
        direction: rtl;
    }

    .login > div.form > div.form--topic {
        width: 80%;
        height: 30%;
        top: 10px;
        left: 10%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: solid 2px rgb(185,55,20);
        border-radius: 10px; */
    }

    .login > div.form > div.form--topic > p.welcome {
        font-size: 2.5rem;
        width: 70%;
        color: rgb(30,30,30);
        text-align: center;
        font-weight: bold;
    }

    .login > div.form > div.form--topic > p.welcome > span {
        color: rgb(185,55,20);
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 5px 0 3px;
    }

    .login > div.form > div.form--topic > p.loginto {
        font-size: 1rem;
        width: 100%;
        color: rgb(30,30,30);
        text-align: center;
        font-weight: bold;
        margin-top: 10px;
    }

    .login > div.form input {
        background-color: rgb(220,220,220);
        border: solid 2px rgb(150,150,150);
        color: rgb(30,30,30);
        font-size: 3vh;
        border-radius: 1rem;
        height: 7%;
        text-align: center;
        position: absolute;
        direction: ltr;
    }

    .login > div.form input.username {
        left: 5%;
        right: 5%;
        top:calc(30% + 30px);
    }

    .login > div.form input.password {
        display: inline-block;
        left: 5%;
        right: 5%;
        top:calc(40% + 30px);
    }

    .login > div.form  div.passwordbox img {
        position: absolute;
        right: calc(5% + 5px);
        top:calc(43.5% + 30px - 9px);
        width: 18px;
        height: 18px;
        opacity: 0.5;
        background-color: rgb(210,210,210);
        border-radius: 10px;
    }

    .login > div.form button.login--button {
        width: 50%;
        height: 60px;
        background-color: rgb(40,40,40);
        color: white;
        border-color: rgb(0,60,0);
        border-style: solid;
        border-width: 2px;
        position: absolute;
        top:60%;
        right: 25%;
        font-size: clamp(1rem , 4vh , 1.8rem);
        border-radius: 1rem;

        transition: all 0.3s;
    }

    .login > div.form button.login--button:hover {
        background-color: black;
        border-color: rgb(0,180,0);
    }

    .login > div.form > a.loginform--signup {
        width: 30%;
        height: fit-content;
        font-size: 2.5vh;
        background: none;
        /* background-color: black; */
        color: rgb(50,50,50);
        position: absolute;
        top: 80%;
        text-align: center;
        right: 20%;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.5;

        transition: all 0.5s;
    }

    .login > div.form > a.loginform--forgot {
        width: 30%;
        height: fit-content;
        font-size: 2.5vh;
        background: none;
        /* background-color: black; */
        color: rgb(50,50,50);
        position: absolute;
        top: 80%;
        text-align: center;
        left: 20%;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.5;

        transition: all 0.5s;
    }

    .login > div.form > a.loginform--signup:hover , a.loginform--signup:active {
        font-size: 2vh;
        color: rgb(185,55,20);
        opacity: 1;
    }

    .login > div.form > a.loginform--forgot:hover , a.loginform--forgot:active {
        font-size: 2vh;
        color: rgb(185,55,20);
        opacity: 1;
    }

    .login > div.form > span.log--error {
        width: 80%;
        height: fit-content;
        font-size: 1.5vh;
        background: none;
        position: absolute;
        top: 80%;
        text-align: center;
        right: 10%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
}
@media (orientation: portrait) { 

    div.activation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(30,30,30);
        display: inline-block;
    }

    div.activation > span.topic {
        position: absolute;
        top: 5%;
        left: 0;
        width: 100%;
        color: white;
        font-size: 1.2rem;
        text-align: center;
        direction: rtl;
    }

    div.activation > div.activation--box {
        position: absolute;
        top: calc(10% + 20px);
        left: 5%;
        right: 5%;
        height: 15%;
        background-color: black;
        border-radius: 1rem;
        border: solid 0.1rem rgb(200 , 150 , 100);
        box-shadow: 0 5px 20px rgb(200 , 150 , 100);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        transition: all 0.5s;
    }

    div.activation > div.activation--box:hover , div.activation--box:active {
        box-shadow: 0 10px 40px rgb(200 , 150 , 100);
    }

    div.activation > div.activation--box > span {
        color: rgb(185,55,20);
        width: 100%;
        text-align: center;
    }

    div.activation > span.share {
        position: absolute;
        top: 40%;
        left: 5%;
        right: 5%;
        text-align: center;
        color: white;
        font-size: 1.2rem;
        direction: rtl;
    }

    div.activation > div.activation--sharing {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    div.activation > div.activation--sharing > input {
        width: 80%;
        background-color: rgb(100,100,100);
        border: none;
        border-radius: 1rem;
        color: white;
        height: 40px;
        text-align: center;
        font-size: 1rem;
        outline: none;
    }

    div.activation > div.activation--sharing > button {
        width: 40%;
        height: 35px;
        border-radius: 0.5rem;
        border: none;
        background-color: rgb(0,0,0);
        color: white;
        font-size: 1rem;
        transition: all 0.5s;
    }

    div.activation > div.activation--sharing > button:hover , button:active {
        background-color: rgb(0,40,0);
    }
}
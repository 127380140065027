@media (orientation: portrait) {
    .mentor_plans {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;

        background-color: white;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .mentor_plans > div.controlbox {
        position: sticky;
        z-index: 2;
        background-color: rgb(255,255,255,0.8);
        backdrop-filter: blur(2px);
        border-radius: 1rem;
        border-top-right-radius: 0;
        left: 5px;
        top: 0;
        width: 99%;
        height: 10%;
        display: flex;
        flex-direction:row;
        justify-content: space-around;
        align-items: center;
    }
    .mentor_plans > div.controlbox > button.back {
        height: 45px;
        width: 15%;
        background-color: rgb(0,0,0);
        border: solid 0.1rem black;
        color: white;
        font-size: 1.5rem;
        border-radius: 1rem;
        transition: all 0.3s;
    }
    .mentor_plans > div.controlbox > button.back:hover , button.back:active{
        background-color: white;
        color: rgb(180,0,0);
        border-color: rgb(180,0,0);
    }

    .mentor_plans > div.controlbox > div.topic {
        width: 80%;
        height: 45px;
    }
    .mentor_plans > div.controlbox > div.topic span {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        font-size: 1rem;
        font-weight: bold;
        color: rgb(30,30,30);
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        text-shadow: 0px 0px 20px rgba(185, 55, 20 , 1);
    }

    .mentor_plans > div.diets {
        position: absolute;
        top: 15%;
        min-height: 85%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: end;
        gap: 35px;
    }

    .mentor_plans > div.diets> div.diet {
        width: 90%;
        height: 50px;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        direction: rtl;

        cursor: pointer;
        transition: all 0.3s;
    }
    .mentor_plans > div.diets> div.diet > div.diet--index {
        margin-right: 5px;
        width: 40px;
        height: 90%;
        background-color: rgb(140 , 150 , 140);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: none;
        box-shadow: 0px 0px 10px 0px rgb(0,120,0);
    }

    .mentor_plans > div.diets> div.diet > div.diet--index > span {
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1rem;
        font-weight: bold;
        color:white;
    }

    .mentor_plans > div.diets> div.diet > div.diet--info {
        margin-right: 20px;
        width: calc(100% - 65px);
        height: 100%;
        background-color: rgb(240 , 235 , 235);
        border-top: solid 0.1rem rgb(185,55,20,0.3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .mentor_plans > div.diets> div.diet > div.diet--info > div.diet--name {
        height: 100%;
        width: 50%;
        margin-right: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: solid 0.1rem rgb(200,200,200);
    }

    .mentor_plans > div.diets> div.diet > div.diet--info > div.diet--name > span {
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1rem;
        font-weight: bold;
    }

    .mentor_plans > div.diets> div.diet > div.diet--info > div.diet--date {
        height: 100%;
        width: 50%;
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mentor_plans > div.diets> div.diet > div.diet--info > div.diet--date > span {
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 0.9rem;
        font-weight: bold;
    }
    

     div.diet--modal {
        top: 0;
        left: 0;
        display: none;
        visibility: hidden;
        width: 100%;
        height: 100%;
        background-color: rgb(100,100,100); /* Fallback color */
        background-color: rgba(100,100,100,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        z-index: 10000;
    
        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    div.diet--modal > div.modal--dietbox {
        width: 98%;
        height: 85%;
        background-color: rgb(60,60,60);
        color: black;
        position: relative;
        border-radius: 15px;
        opacity: 0;
        overflow: auto;
        transition: all 0.3s;
    }

    div.diet--modal > div.share {
        width: 100%;
        font-size: 2rem;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        direction: rtl;
    }

    div.diet--modal > div.share button {
        width: 30%;
        height: 80%;
        background-color: rgb(220, 220, 220);
        color: black;
        font-size: 3vh;
        border-radius: 1rem;
        border: none;
        
        transition: all 0.3s;
    }

    div.diet--modal > div.share button.share--button:hover,button.share--button:active {
        border-color: rgb(0,60,0);
        color: rgb(0 , 150 , 0);
    }
    div.diet--modal > div.share button.delete--button:hover,button.delete--button:active {
        color: rgb(150, 0, 0);
        border-color: rgb(60,0,0);
    }
    div.diet--modal > div.share button.back--button:hover,button.back--button:active {
        color: rgb(185, 55, 20);
        border-color: rgb(30,0,0);
    }

    div.shareDiet--modal {
        top: 0;
        left: 0;
        display: none;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 10001;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    
        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    div.shareDiet--modal > div.modal--shareDietbox {
        width: 80%;
        height: 80%;
        background-color: #000000;
        border: solid 2px rgb(0 , 60 , 0);
        border-radius: 20px;
        display: flex;
        opacity: 0;

        transition: all 0.3s;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button {
        width: 100%;
        height: 50%;
        font-size: 4vh;
        color: white;
        background-color: #000000;
        border: none;

        transition: all 0.3s;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button:hover , button:active {
        color: rgb(185, 55, 20);
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button.qrcode {
       border-bottom: solid 1px rgb(185, 55, 20);
       border-top-left-radius: 20px;
       border-top-right-radius: 20px;
    }
    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button.link {
        border-top: solid 1px rgb(185, 55, 20);
        border-bottom: solid 1px rgb(185, 55, 20);
     }
     div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button.pdfdownload {
        border-top: solid 1px rgb(185, 55, 20);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
     }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > .Qr {
        width: 80%;
        height: 50%;
        border: solid 1px rgb(185, 55, 20);
        border-radius: 20px;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > .back {
        width: 50%;
        height: 8%;
        margin-top: 10%;
        font-size: 3vh;
        border: solid 2px white;
        border-radius: 15px;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        direction: rtl;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .topic {
        width: 100%;
        height: 10%;
        font-size: 3vh;
        color: white;
        text-align: center;
        margin-top: 10%;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .link {
        width: 100%;
        height: 10%;
        font-size: 2vh;
        color: rgb(185, 55, 20);
        text-align: center;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .phoneTopic {
        width: 80%;
        height: 10%;
        font-size: 2vh;
        color: white;
        text-align: center;
        margin-top: 30%;
        margin-right: 10%;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .phone {
        width: 70%;
        height: 6%;
        font-size: 2vh;
        color: black;
        text-align: center;
        border: 2px solid rgb(185, 55, 20);
        border-radius: 15px;
        margin-right: 15%;
        margin-left: 15%;
        margin-top: 0;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .send {
        width: 50%;
        height: 6%;
        font-size: 2vh;
        color: white;
        background-color: black;
        border: solid 1px white;
        text-align: center;
        border-radius: 10px;
        margin-right: 25%;
        margin-top: 5%;

        transition: all 0.3s;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .send:hover , .send:active {
        color: rgb(0 , 150 , 0);
        border-color: rgb(0 , 150 , 0);
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .back {
        width: 100%;
        height: 15%;
        font-size: 2vh;
        color: white;
        background-color: black;
        border: solid 1px rgb(0 , 60 , 0);
        text-align: center;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        margin-top: auto;

        transition: all 0.3s;
    }

    div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .back:hover , .back:active {
        color: rgb(185, 55, 20);
        border-color: rgb(185, 55, 20);
    }

    div.deleteDiet--modal {
        top: 0;
        left: 0;
        display: none;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 10001;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    
        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    div.deleteDiet--modal > div.modal--deleteDietbox {
        width: 80%;
        height: 50%;
        background-color: #000000;
        border: solid 2px rgb(0 , 60 , 0);
        border-radius: 20px;
        display: flex;
        opacity: 0;

        transition: all 0.3s;
    }
}
@media (orientation: portrait) {
    div.packages {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(245,250,245);
        display: inline-block;
    }

    div.packages > span.topic {
        position: absolute;
        top: 15%;
        left: 10%;
        right: 10%;
        height: 15%;
        font-size: clamp(1rem , 4vh , 1.5rem);
        font-weight: bold;
        text-align: center;
        direction: rtl;
        color: rgb(185,55,20);
    }

    div.packages > div.box {
        width: 130px;
        height: 130px;
        border-radius: 40px;
        /* border: solid rgb(200,200,200) 0.01rem; */
        background-color: rgb(50,100,50);
        display: inline-block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 20px rgb(185,55,20);

        transition: all 0.3s;
    }

    div.packages > div.box:hover , div.box:active {
        background-color: black;
        cursor: pointer;
    }

    div.packages > div.box.a {
        position: absolute;
        top: 50%;
        right: 3%;
    }

    div.packages > div.box.b {
        position: absolute;
        top: 50%;
        left: 3%;
    }

    div.packages > div.box > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.05;
        border-radius: 60px;
    }

    div.packages > div.box > span {
        position: absolute;
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 1.1rem;
        font-weight: bold;
        color: white;
        top: 50px;
        /* width: 100%; */
    }
}
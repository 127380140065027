@media (orientation: portrait) {
    .mymodal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.2); /* Black w/ opacity */
        backdrop-filter: blur(5px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 10002;
        opacity: 0;
        transition: all 0.5s;
    }

    .mymodal.fade-in {
        opacity: 1; /* Fully visible */
    }

    .mymodal.fade-out {
        opacity: 0; /* Fully hidden */
    }
}

.alert {
    position: fixed;
    top: 10px;
    left: 10%;
    right: 10%;
    min-height: 90px;
    height: fit-content;
    /* border: solid 0.1rem rgb(200,200,200); */
    border-radius: 1rem;
    z-index: 10004;
    box-shadow: 0 2px 20px 3px rgb(0,200,0);
    opacity: 0; 
    transition: all 1s ease; 
}

.alert.fade-in {
    opacity: 1; /* Fully visible */
}

.alert.fade-out {
    opacity: 0; /* Fully hidden */
}

.alert > .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 0;
    left: 5%;
    right: 5%;
    height: 50%;
    
}

.alert > .content > span {
    font-size: 0.9rem;
    direction: rtl;
    color: white;
}

.alert > button {
    background-color: rgb(100,100,100,0.9);
    border: none;
    cursor: pointer;
    font-weight: bold;
    border: 0.1rem solid rgb(30,30,30);
    border-radius: 0.5rem;
    position: absolute;
    top: 60%;
    height: 30%;
    left: 30%;
    right: 30%;
    /* background-color: rgb(80,80,80); */
    color: white;
}


@media (orientation: portrait) {
    div.create_package {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgb(255, 230, 225), rgb(255,255,255)); 
        display: inline-block;
    }

    div.create_package > span.topic {
        position: absolute;
        top: 8%;
        left: 0;
        width: 100%;
        height: 5%;
        font-size: 1.5rem;
        color:rgb(185,55,20);
        font-weight: bold;
        text-align: center;
    }

    div.create_package > div.create {
        position: absolute;
        top: 15%;
        left: 0;
        width: 100%;
        height: 75%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    div.create_package > div.create > div.section.a {
        position: absolute;
        top: 5%;
        left: 0;
        width: 100%;
        height: 25%;
        display: inline-block;
    }
    div.create_package > div.create > div.section.b {
        position: absolute;
        top: 30%;
        left: 0;
        width: 100%;
        height: 25%;
        display: inline-block;
    }
    div.create_package > div.create > div.section.c {
        position: absolute;
        top: 55%;
        left: 0;
        width: 100%;
        height: 25%;
        display: inline-block;
    }
    div.create_package > div.create > div.section.d {
        position: absolute;
        top: 80%;
        left: 0;
        width: 100%;
        height: 16%;
        display: inline-block;
    }

    div.create_package > div.create > div.section > span {
        position: absolute;
        top: 0;
        width: 100%;
        height: 25%;
        text-align: center;
        font-size: 1.1rem;
        font-weight: bold;
        color: rgb(100,100,100);
        /* border-top: solid 0.1rem rgb(100,100,100); */
    }
    div.create_package > div.create > div.section > div.selector {
        position: absolute;
        top: 25%;
        width: 100%;
        height: 75%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        direction: rtl;
    }

    div.create_package > div.create > div.section > div.selector > div.boxes {
        margin-top: 10px;
        width: 136px;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    div.create_package > div.create > div.section > div.selector > div.value {
        margin-top: 10px;
        width: 100px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: solid 0.1rem rgb(100,100,100); */
        border-radius: 30px;
        background-color: rgb(190,230,190);
    }

    div.create_package > div.create > div.section > div.selector > div.boxes > div.box {
        /* position: absolute; */
        width: 19px;
        height: 19px;
        border: solid 2px rgb(200,200,200);
        border-radius: 7px;
        transition: all 0.5s;
    }

    div.create_package > div.create > div.section > div.selector > div.boxes > div.box:hover {
        background-color: rgb(250,220,220) !important;
    }

    div.create_package > div.create > div.section > div.selector > div.value > span {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1.1rem;
        font-weight: bold;
        text-align: center;
        color: white;
        transition: all 0.5s;
    }

    div.create_package > div.create > div.section.d > div.selector {
        margin-top: 5px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    div.create_package > div.create > div.section.d > div.selector > div.dietmode {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    div.create_package > div.create > div.section.d > div.selector > div.dietmode > img {
        width: 35px;
        height: 35px;
        opacity: 0.5;
        border-radius: 10px;
        /* background-color: rgb(100,100,100); */
        transition: all 0.3s;
    }

    div.create_package > div.create > div.section.d > div.selector > div.dietmode > span {
        width: 100%;
        text-align: center;
        font-size: 0.8rem;
        color: rgb(200,150,150);
    }

    div.create_package > div.create > div.section.d > div.selector > div.workoutmode {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    div.create_package > div.create > div.section.d > div.selector > div.workoutmode > img {
        width: 35px;
        height: 35px;
        opacity: 0.5;
        border-radius: 10px;
        /* background-color: rgb(100,100,100); */
        transition: all 0.3s;
    }

    div.create_package > div.create > div.section.d > div.selector > div.workoutmode > span {
        width: 100%;
        text-align: center;
        font-size: 0.8rem;
        color: rgb(200,150,150);
    }

    div.create_package > div.activate {
        position: absolute;
        top: 81%;
        width: 100%;
        height: 19%;
        display: inline-block;
    }
    div.create_package > div.activate > div.price {
        position: absolute;
        top: 20%;
        right: 5%;
        height: 60%;
        width: 50%;
        background-color: rgb(240,240,240);
        /* border: rgb(185,55,20) solid 0.1rem; */
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0px 10px  rgb(185,55,20);
    }
    div.create_package > div.activate > div.price > p {
        width: 80%;
        font-size: 1.2rem;
        color: rgb(100,100,100);
        direction: rtl;
    }
    div.create_package > div.activate > div.price > p > span {
        margin-left: 10px;
        font-size: 1.1rem;
        color: black;
        opacity: 1;
        transition: all 0.3s;
    }
    div.create_package > div.activate > button {
        position: absolute;
        top: 20%;
        left: 5%;
        width: 30%;
        height: 60%;
        background-color: rgb(0,50,0);
        border-radius: 2rem;
        border: none;
        color: white;
        font-size: 1.1rem;
        border: solid 0.1rem rgb(0,50,0);
        /* font-weight: bold; */
        transition: all 0.3s;
    }

    div.create_package > div.activate > button:hover ,button:active {
        background-color: white;
        color: rgb(0,50,0);
        
    }
}



div.makedonate {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: rgb(230,240,230);
}

div.makedonate > span.topic {
    position: absolute;
    top: 5%;
    left: 0%;
    height: 80px;
    width: 100%;
    font-size: clamp(1.6rem , 10vw , 2.5rem);
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: rgb(0,120,0);
    font-weight: bold;
    direction: rtl;
    box-shadow: 0px 0px 20px 4px rgb(255,255,255);
}

div.makedonate > div.mode {
    position: absolute;
    top: 30%;
    width: calc(100% - 100px);
    bottom: 20%;
    display: inline-block;
    border-radius: 1rem;
    background-color: white;
    transition: all 0.3s;
}

div.makedonate > div.mode.zero {
    left: 50px;
    box-shadow: 0px 0px 15px 0px rgb(190,90,90);
}

div.makedonate > div.mode.one {
    left: 1000px;
    box-shadow: 0px 0px 15px 0px rgb(90,190,90);
}

div.makedonate > div.mode.two {
    left: 2000px;
    box-shadow: 0px 0px 15px 0px rgb(90,90,190);
}

div.makedonate > div.mode > span.topic {
    position: absolute;
    top: 10px;
    left: 5%;
    width: 90%;
    color: rgb(185,55,20);
    font-weight: bold;
    font-size: clamp(1rem , 4.5vw , 1.3rem);
    text-align: center;
}

div.makedonate > div.mode > div.controlbox {
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    direction: rtl;
}

div.makedonate > div.mode > div.controlbox  button {
    width: 80px;
    height: 40px;
    margin-bottom: 10px;
    border: none;
    border-radius: 1rem;
    transition: all 0.3s;
    cursor: pointer;
}

div.makedonate > div.mode > div.controlbox  button.next {
    background-color: rgb(100,180,100);
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

div.makedonate > div.mode > div.controlbox button.back {
    background-color: rgb(190,90,90);
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

div.makedonate > div.mode > div.controlbox button:hover {
    background-color: black;
    box-shadow: 0px 0px 10px rgb(185,55,20);
    color: rgb(185,55,20);
}

div.makedonate > div.mode.zero > input:hover , input:active , input:focus {
    box-shadow: 0px 0px 10px rgb(185,55,20);
}
div.makedonate > div.mode.zero > input {
    transition: all 0.8s;
}

div.makedonate > div.mode.zero > input.firstname {
    position: absolute;
    top: calc(20% + 10px);
    right: 5px;
    width: 45%;
    height: 40px;
    background-color: rgb(200,200,200);
    border: none;
    outline: none;
    text-align: center;
    border-radius: 1rem;
    font-size: 1.1rem;
    color: black;
}

div.makedonate > div.mode.zero > input.lastname {
    position: absolute;
    top: calc(20% + 10px);
    left: 5px;
    width: 45%;
    height: 40px;
    background-color: rgb(200,200,200);
    border: none;
    outline: none;
    text-align: center;
    border-radius: 1rem;
    font-size: 1.1rem;
    color: black;
}

div.makedonate > div.mode.zero > input.phone {
    position: absolute;
    top: calc(20% + 70px);
    left: 5px;
    right: 5px;
    height: 40px;
    background-color: rgb(200,200,200);
    border: none;
    outline: none;
    text-align: center;
    border-radius: 1rem;
    font-size: 1.1rem;
    color: black;
}

div.makedonate > div.mode.one > input.verifycode {
    position: absolute;
    top: calc(30% + 10px);
    right: 5px;
    width: 60%;
    height: 40px;
    background-color: rgb(200,200,200);
    border: none;
    outline: none;
    text-align: center;
    border-radius: 1rem;
    font-size: 1.1rem;
    color: black;
}

div.makedonate > div.mode.one > button.sendagain {
    position: absolute;
    top: calc(30% + 10px);
    left: 5px;
    width: 30%;
    height: 40px;
    background-color: rgb(30,30,30);
    border: none;
    outline: none;
    text-align: center;
    border-radius: 1rem;
    font-size: clamp(0.8rem , 4vw , 1rem);
    color: white;
    cursor: pointer;
}

div.makedonate > div.mode.two > input {
    position: absolute;
    top: calc(30% + 10px);
    right: 5px;
    left: 5px;
    height: 40px;
    background-color: rgb(200,200,200);
    border: none;
    outline: none;
    text-align: center;
    border-radius: 1rem;
    font-size: 1.1rem;
    color: black;
}

div.makedonate > div.mode.two > div.togglebox {
    position: absolute;
    top: calc(30% + 70px);
    right: 5px;
    left: 5px;
    height: 60px;
}
@media (orientation: landscape) {
    .forgot {
        width: 100%;
        height: 100%;
        background-color: rgb(220,230,220);
        margin: 0;
        position: absolute;
        overflow: hidden;
        display: flex;
    }

    .forgot input:focus {
        outline: none;
    }

    .forgot > div.form {
        position: absolute;
        top: 15%;
        left: 0%;
        right: 0%;
        height: 85%;
        background-color: rgb(10,10,10);
        display: inline-block;
    }

    .forgot > div.form.phone > span.topic {
        position: absolute;
        top: 30%;
        width: 100%;
        height: 10%;
        text-align: center;
        font-size: 5vh;
        color: rgb(230,230,230);
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    }

    .forgot > div.form.phone > input.phone {
        position: absolute;
        top: 45%;
        left: 30%;
        right: 30%;
        height: 10%;
        font-size: 3vh;
        border: solid 0.1rem black;
        border-radius: 0.5rem;
        background-color: rgb(40,40,40);
        color: rgb(230,230,230);
        text-align: center;
    }

    .forgot > div.form.phone > button.send {
        position: absolute;
        top: 58%;
        left: 40%;
        right: 40%;
        height: 10%;
        font-size: 4vh;
        background-color: black;
        border: solid black 0.1rem;
        border-radius: 1rem;
        color: white;
        text-align: center;

        transition: all 0.5s;
    }

    .forgot > div.form.phone > button.send:hover , button.send:active {
        border-color: rgb(0,120,0);
    }

    .forgot > div.form.phone > span.log--error {
        position: absolute;
        font-size: 2.5vh;
        top: 70%;
        width: 100%;
        height: 5%;
        text-align: center;
    }



    .forgot > div.form.password > span.topic {
        position: absolute;
        top: 30%;
        width: 100%;
        height: 10%;
        text-align: center;
        font-size: 5vh;
        color: rgb(230,230,230);
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    }

    .forgot > div.form.password > input.newpassword {
        position: absolute;
        top: 40%;
        left: 30%;
        right: 30%;
        height: 10%;
        border: solid 0.1rem black;
        border-radius: 0.5rem;
        background-color: rgb(40,40,40);
        color: rgb(230,230,230);
        font-size: 3vh;
        text-align: center;
    }

    .forgot > div.form.password > input.newpassword.verify {
        position: absolute;
        top: 52%;
        left: 30%;
        right: 30%;
        height: 10%;
        border: solid 0.1rem black;
        border-radius: 0.5rem;
        background-color: rgb(40,40,40);
        color: rgb(230,230,230);
        font-size: 3vh;
        text-align: center;
    }

    .forgot > div.form.password > button.send {
        position: absolute;
        top: 65%;
        left: 40%;
        right: 40%;
        height: 10%;
        font-size: 4vh;
        background-color: black;
        border: solid black 0.1rem;
        border-radius: 1rem;
        color: white;
        text-align: center;

        transition: all 0.5s;
    }

    .forgot > div.form.password > button.send:hover , button.send:active {
        border-color: rgb(0,120,0);
    }

    .forgot > div.form.password > span.log--error {
        position: absolute;
        font-size: 2.5vh;
        top: 78%;
        width: 100%;
        height: 5%;
        text-align: center;
    }

}

@media (orientation: portrait) {
    .forgot {
        width: 100%;
        height: 100%;
        background-color: rgb(245,250,245);
        margin: 0;
        position: absolute;
        overflow: hidden;
        display: flex;
    }

    .forgot input:focus {
        outline: none;
    }

    .forgot > div.form {
        position: absolute;
        top: 15%;
        left: 0%;
        right: 0%;
        height: 85%;
        background-color: rgb(245,250,245);
        display: inline-block;
    }

    .forgot > div.form.phone > span.topic {
        position: absolute;
        top: 30%;
        width: 100%;
        height: 10%;
        text-align: center;
        font-size: 1.5rem;
        color: black;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    }

    .forgot > div.form.phone > input.phone {
        position: absolute;
        top: 45%;
        left: 5%;
        right: 5%;
        height: 10%;
        font-size: 1.3rem;
        border: solid 0.1rem black;
        border-radius: 0.5rem;
        background-color: rgb(100,100,100);
        color: white;
        text-align: center;
    }

    .forgot > div.form.phone > button.send {
        position: absolute;
        top: 60%;
        left: 25%;
        right: 25%;
        height: 10%;
        font-size: 1.3rem;
        background-color: black;
        border: solid black 0.1rem;
        border-radius: 1rem;
        color: white;
        text-align: center;

        transition: all 0.5s;
    }

    .forgot > div.form.phone > button.send:hover , button.send:active {
        border-color: rgb(0,120,0);
    }

    .forgot > div.form.phone > span.log--error {
        position: absolute;
        font-size: 0.9rem;
        top: 75%;
        width: 100%;
        height: 5%;
        text-align: center;
    }



    .forgot > div.form.password > span.topic {
        position: absolute;
        top: 25%;
        height: 10%;
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        color: rgb(30,30,30);
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    }

    .forgot > div.form.password > input.newpassword {
        position: absolute;
        top: 38%;
        left: 5%;
        right: 5%;
        height: 8%;
        border: solid 0.1rem black;
        border-radius: 0.5rem;
        background-color: rgb(100,100,100);
        color: white;
        font-size: 1.3rem;
        text-align: center;
    }

    .forgot > div.form.password > input.newpassword.verify {
        position: absolute;
        top: 48%;
        left: 5%;
        right: 5%;
        height: 8%;
        border: solid 0.1rem black;
        border-radius: 0.5rem;
        background-color: rgb(100,100,100);
        color: white;
        font-size: 1.3rem;
        text-align: center;
    }

    .forgot > div.form.password > button.send {
        position: absolute;
        top: 60%;
        left: 25%;
        right: 25%;
        height: 10%;
        font-size: 1.3rem;
        background-color: black;
        border: solid black 0.1rem;
        border-radius: 1rem;
        color: white;
        text-align: center;

        transition: all 0.5s;
    }

    .forgot > div.form.password > button.send:hover , button.send:active {
        border-color: rgb(0,120,0);
    }

    .forgot > div.form.password > span.log--error {
        position: absolute;
        font-size: 0.9rem;
        top: 75%;
        width: 100%;
        height: 5%;
        text-align: center;
    }

}



div.menubar {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    z-index: 1000;
    background-color: rgb(255,255,255,0.8);
    backdrop-filter: blur(3px);
    /* border-radius: 2rem; */
}

div.menubar > button {
    position: absolute;
    top: 20%;
    right: 5%;
    width: clamp(80px , 25% , 120px);
    height: 50%;
    border-radius: 0.5rem;
    background-color: rgb(255,250,245);
    color: white;
    font-size: clamp(0.8rem , 5vw , 1.2rem);
    border: solid 0.05rem rgb(185,55,20);
    transition: all 0.3s;
    color: rgb(220,120,70);
    font-weight: bold;
}

div.menubar > button:hover , button:active {
    border-color: rgb(0,120,0);
}

div.menubar > div.logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

div.menubar > div.logo span {
    position: absolute;
    left: 40px;
    width: 120px;
    height: 90%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: rgb(40,40,40);
    filter: brightness(100%);
    -webkit-animation:lightning01 10s cubic-bezier(.0, .5, .5, 1)  infinite;
    -moz-animation:lightning01 10s cubic-bezier(.0, .5, .5, 1) infinite;
}

div.menubar > div.logo img {
    position: absolute;
    top: 0;
    left: -10px;
    margin-top: 1px;
    width: 80px;
    height: 80px;
    filter: brightness(100%);
    -webkit-animation:lightning01 10s cubic-bezier(.0, .5, .5, 1)  infinite;
    -moz-animation:lightning01 10s cubic-bezier(.0, .5, .5, 1) infinite;
    animation:lightning01 10s cubic-bezier(.0, .5, .5, 1) infinite;
}

div.menubar > div.logo img:hover , img:active {
    filter: brightness(100%) !important;
    opacity: 1 !important;
}

@-moz-keyframes lightning01 { 
    50% {opacity: 0.3;  filter: brightness(20%);} 
}
@-webkit-keyframes lightning01 { 
    50% {opacity: 0.3;  filter: brightness(20%);} 
}
@keyframes lightning01 { 
    50% {opacity: 0.3; filter: brightness(20%);} 
}
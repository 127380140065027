@media (orientation: landscape) {
    .verify.body {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        border-radius: 20px;
    }

    .verify.body > div.smsVerification {
        width: 100%;
        height: 100%;
        background-color: rgb(10,10,10);
        display: inline-block;
        opacity: 1;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > span.topic {
        position: absolute;
        width: 100%;
        height: 15%;
        top: 25%;
        display: flex;
        text-align: center;
        justify-content: center;
        font-size: 3vh;
        color: white;
    }

    .verify.body > div.smsVerification > div.digitsinput {
        position: absolute;
        width: 80%;
        top: 40%;
        right: 10%;
        height: 15%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .verify.body > div.smsVerification > div.digitsinput > input.digit {
        width: 10%;
        height: 60%;
        background-color: rgb(40,40,40);
        color: white;
        font-size: 3vh;
        text-align: center;
        border-radius: 1rem;
        border: solid 0.1rem black;
        transition: all 0.2s;
    }

    .verify.body > div.smsVerification > div.digitsinput > input.digit:focus {
        background-color: rgb(80,80,80);
        outline: none;
    }


    .verify.body > div.smsVerification > button.verify {
        position: absolute;
        width: 40%;
        height: 10%;
        top: 60%;
        right: 30%;
        font-size: 3vh;
        background-color: rgb(30,30,30);
        color: white;
        border: 0.1rem solid rgb(0,60,0);
        border-radius: 1rem;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > button.verify:hover {
        border-color: rgb(0,180,0);
        background-color: black;
    }

    .verify.body > div.smsVerification > div.buttonseperator {
        position: absolute;
        width: 100%;
        height: 30%;
        top: 70%;
        display: inline-block;
    }
    
    .verify.body > div.smsVerification > div.buttonseperator > button.resend {
        position: absolute;
        width: 30%;
        height: 20%;
        top: 40%;
        right: 15%;
        font-size: 2.5vh;
        background-color: rgb(30,30,30);
        color: white;
        border: 0.1rem solid black;
        border-radius: 1rem;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.resend:hover {
        border-color: rgb(150, 150, 0);
        background-color: black;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.justifyPhone {
        position: absolute;
        width: 30%;
        height: 20%;
        top: 40%;
        left: 15%;
        font-size: 2.5vh;
        background-color: rgb(30,30,30);
        color: white;
        border: 0.1rem solid black;
        border-radius: 1rem;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.justifyPhone:hover {
        border-color: rgb(185,55,20);
        background-color: black;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.passaway {
        position: absolute;
        width: 70%;
        font-size: 2vh;
        background-color: rgb(10,10,10);
        top: 70%;
        left: 15%;
        height: 20%;
        color: white;
        border: 2px solid white;
        border-radius: 5px;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.passaway:hover , button.passaway:active {
        color: rgb(185,55,20);
        background-color: black;
    }




    .verify.body > div.justifyPhone {
        width: 100%;
        height: 100%;
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 20px;
        opacity: 0;

        transition: all 0.3s;
    }

    .verify.body > div.justifyPhone > input.newPhone {
        width: 40%;
        font-size: 2vh;
        text-align: center;
        margin-top: 20%;
        height: 10%;
        color: black;
    }

    .verify.body > div.justifyPhone > input.newPhone:focus {
        outline: none;
    }

    .verify.body > div.justifyPhone > button.justifyPhone--button {
        width: 30%;
        height: 10%;
        margin-top: 10%;
        font-size: 2vh;
        background-color: black;
        color: white;
        border: 2px solid white;
        border-radius: 15px;

        transition: all 0.3s;
    }

    .verify.body > div.justifyPhone > button.justifyPhone--button:hover {
        border-color: rgb(0,60,0);
    }

}

@media (orientation: portrait) {
    .verify.body {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        border-radius: 20px;
    }

    .verify.body > div.smsVerification {
        width: 100%;
        height: 100%;
        background-color: rgb(10,10,10);
        display: inline-block;
        opacity: 1;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > span.topic {
        position: absolute;
        width: 95%;
        height: 20%;
        top: 20%;
        right: 2.5%;
        display: flex;
        text-align: center;
        justify-content: center;
        font-size: 3vh;
        color: white;
    }

    .verify.body > div.smsVerification > div.digitsinput {
        position: absolute;
        width: 95%;
        top: 40%;
        right: 2.5%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .verify.body > div.smsVerification > div.digitsinput > input.digit {
        width: 14%;
        height: 65%;
        background-color: rgb(40,40,40);
        color: white;
        font-size: 3vh;
        text-align: center;
        border-radius: 1rem;
        border: solid 0.1rem black;
        transition: all 0.2s;
    }

    .verify.body > div.smsVerification > div.digitsinput > input.digit:focus {
        background-color: rgb(80,80,80);
        outline: none;
    }

    .verify.body > div.smsVerification > button.verify {
        position: absolute;
        width: 60%;
        height: 10%;
        top: 55%;
        right: 20%;
        font-size: 3vh;
        background-color: rgb(30,30,30);
        color: white;
        border: 0.1rem solid rgb(0,60,0);
        border-radius: 1rem;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > button.verify:hover, button.verify:active , button.verify:focus {
        border-color: rgb(0,180,0);
        background-color: black;
    }

    .verify.body > div.smsVerification > div.buttonseperator {
        position: absolute;
        width: 100%;
        height: 35%;
        top: 65%;
        display: inline-block;
    }
    
    .verify.body > div.smsVerification > div.buttonseperator > button.resend {
        position: absolute;
        width: 40%;
        height: 20%;
        top: 40%;
        right: 8%;
        font-size: 2.5vh;
        background-color: rgb(30,30,30);
        color: white;
        border: 0.1rem solid black;
        border-radius: 1rem;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.resend:hover {
        border-color: rgb(150, 150, 0);
        background-color: black;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.justifyPhone {
        position: absolute;
        width: 40%;
        height: 20%;
        top: 40%;
        left: 8%;
        font-size: 2.5vh;
        background-color: rgb(30,30,30);
        color: white;
        border: 0.1rem solid black;
        border-radius: 1rem;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.justifyPhone:hover {
        border-color: rgb(185,55,20);
        background-color: black;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.passaway {
        position: absolute;
        width: 84%;
        font-size: 2vh;
        background-color: rgb(10,10,10);
        top: 70%;
        left: 8%;
        height: 20%;
        color: white;
        border: 2px solid white;
        border-radius: 5px;

        transition: all 0.3s;
    }

    .verify.body > div.smsVerification > div.buttonseperator > button.passaway:hover , button.passaway:active {
        color: rgb(185,55,20);
        background-color: black;
    }




    .verify.body > div.justifyPhone {
        width: 100%;
        height: 100%;
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 20px;
        opacity: 0;

        transition: all 0.3s;
    }

    .verify.body > div.justifyPhone > input.newPhone {
        width: 40%;
        font-size: 2vh;
        text-align: center;
        margin-top: 20%;
        height: 10%;
        color: black;
    }

    .verify.body > div.justifyPhone > input.newPhone:focus {
        outline: none;
    }

    .verify.body > div.justifyPhone > button.justifyPhone--button {
        width: 30%;
        height: 10%;
        margin-top: 10%;
        font-size: 2vh;
        background-color: black;
        color: white;
        border: 2px solid white;
        border-radius: 15px;

        transition: all 0.3s;
    }

    .verify.body > div.justifyPhone > button.justifyPhone--button:hover {
        border-color: rgb(0,60,0);
    }

}

@media (orientation: portrait) {
    .signupentry {
        width: 100%;
        height: 100%;
        background-color: rgb(245,250,245);
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        display: flex;
    }

    .signupentry > div.form {
        position: absolute;
        top: 80px;
        left: 0%;
        width: 100%;
        height: 90%;
        display: inline-block;
        background-color: rgb(245,250,245);
        direction: rtl;
    }

    .signupentry > div.form.mode > div.form--topic {
        width: 80%;
        height: 30%;
        top: 20px;
        left: 10%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .signupentry > div.form.mode > div.form--topic > p.welcome {
        font-size: 2.5rem;
        width: 70%;
        color: rgb(30,30,30);
        text-align: center;
        font-weight: bold;
    }

    .signupentry > div.form.mode > div.form--topic > p.welcome > span {
        color: rgb(185,55,20);
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 5px 0 3px;
    }

    .signupentry > div.form.mode > div.form--topic > p.topic.role {
        font-size: 1rem;
        width: 100%;
        color: rgb(30,30,30);
        text-align: center;
        font-weight: bold;
        margin-top: 10px;
    }

    .signupentry > div.form.mode > div.roles {
        position: absolute;
        width: 100%;
        height: 20%;
        top: 35%;
        left:0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .signupentry > div.form.mode > div.roles > button {
        width: 30%;
        height: 70px;
        background-color: rgb(240,240,240);
        color: black;
        border: solid 0.1rem rgb(220,220,220);
        font-size: 1.5rem;
        border-radius: 2rem;
        transition: all 0.5s;
    }
    .signupentry > div.form.mode > div.roles > button.mode:hover {
        /* color: rgb(185,55,20); */
        /* border-color: rgb(185,55,20); */
        background-color: rgb(185,55,20);
        color: white;
    }

    /*  */
    .csignup {
        width: 100%;
        height: 100%;
        background-color: rgb(245,250,245);
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        display: flex;
    }
    
    .csignup input:focus {
        outline: none;
    }
    
    .csignup > div.form {
        position: absolute;
        top: 80px;
        left: 0%;
        width: 100%;
        height: 90%;
        display: inline-block;
        background-color: rgb(245,250,245);
        direction: rtl;
    }

    .csignup > div.form > div.form--topic {
        width: 100%;
        height: 10%;
        top: 10%;
        left: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .csignup > div.form > div.form--topic > span {
        font-size: 1.3rem;
        font-weight: bold;
    }

    .csignup > div.form input {
        background-color: rgb(230,230,230);
        border: solid 2px rgb(150,150,150);
        color: rgb(30,30,30);
        font-size: 3vh;
        border-radius: 0.5rem;
        height: 7%;
        text-align: center;
        position: absolute;
    }

    .csignup > div.form input.username {
        left: 2.5%;
        right: 2.5%;
        top:20%;
        direction: ltr;
    }

    .csignup > div.form input.activation_code {
        left: 2.5%;
        /* height: 5%; */
        right: 2.5%;
        top:20%;
    }

    .csignup > div.form input.firstname {
        left: 51.25%;
        right: 2.5%;
        top:30%;
    }

    .csignup > div.form input.lastname {
        left: 2.5%;
        right: 51.25%;
        top: 30%;
    }

    .csignup > div.form input.phone {
        left: 2.5%;
        right: 2.5%;
        top: 40%;
        direction: ltr;
    }

    .csignup > div.form input.email {
        left: 2.5%;
        right: 2.5%;
        top:50%;
        direction: ltr;
    }

    .csignup > div.form input.password {
        left: 51.25%;
        right: 2.5%;
        top:60%;
        direction: ltr;
    }

    .csignup > div.form input.password--verify {
        left: 2.5%;
        right: 51.25%;
        top:60%;
        direction: ltr;
    }


    .csignup > div.form button.signup--button {
        width: 50%;
        height: 50px;
        background-color: rgb(40,40,40);
        color: white;
        border-color: rgb(0,60,0);
        border-style: solid;
        border-width: 2px;
        position: absolute;
        top:70%;
        right: 25%;
        font-size: 4vh;
        border-radius: 1rem;

        transition: all 0.3s;
    }

    .csignup > div.form button.signup--button:hover {
        background-color: rgb(0,100,0);
    }

    .csignup > div.form > a.login {
        width: 60%;
        height: fit-content;
        font-size: 2.5vh;
        background: none;
        /* background-color: black; */
        color: rgb(50,50,50);
        position: absolute;
        top: 83%;
        text-align: center;
        right: 20%;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.5;

        transition: all 0.3s;
    }

    .csignup > div.form > a.login:hover {
        font-size: 1.6vh;
        color: rgb(185,55,20);
        opacity: 1;
    }

    .csignup > div.form > span.log--error {
        width: 80%;
        height: fit-content;
        font-size: 3vh;
        background: none;
        position: absolute;
        top: 90%;
        text-align: center;
        right: 10%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    
}
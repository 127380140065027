@media (orientation: portrait) {
    .diet_plans_box {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;

        background-color: white;
    }

    .diet_plans_box > .plansbar {
        position: absolute;
        top: 0;
        left: 20px;
        right: 0;
        height: 100%;
        transition: all 0.5s;
        background-color: rgb(245,255,245);
    }

    .diet_plans_box > .sidebar {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        width: 60%;
        height: 100%;
        background-color: rgb(245,255,245,0.5);
        backdrop-filter: blur(10px);
        box-shadow: 0px 0px 20px rgb(185,55,20);
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        transition: all 0.5s;
        z-index: 999;
    }

    .diet_plans_box > .sidebar > div.control {
        position: absolute;
        right: 0;
        width: 20px;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .diet_plans_box > .sidebar > div.control > img {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        /* box-shadow: 0 0 10px 0 rgb(0,120,0); */
        opacity: 0.7;
    }

    .diet_plans_box > .sidebar > div.planslist {
        position: absolute;
        width: calc(100% - 20px);
        height: 100%;
        left: 0;
        top: 0;
        display: inline-block;
    }

    .diet_plans_box > .sidebar > div.planslist > div.topic {
        position: absolute;
        top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .diet_plans_box > .sidebar > div.planslist > div.topic > span.t1 {
        text-align: center;
        font-size: 2.3rem;
        color: rgb(185,55,20);
        opacity: 0.9;
        font-weight: bold;
    }

    .diet_plans_box > .sidebar > div.planslist > div.topic > span.t2 {
        text-align: center;
        font-size: 0.8rem;
        color: rgb(185,55,20);
        /*font-weight: bold;*/
    }

    .diet_plans_box > .sidebar > div.planslist > div.topic--mentor {
        position: absolute;
        top: calc(20% + 20px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .diet_plans_box > .sidebar > div.planslist > div.topic--mentor > span {
        text-align: center;
        font-size: 1rem;
        color: rgb(0,0,0);
        opacity: 0.9;
        font-weight: bold;
    }

    .diet_plans_box > .sidebar > div.planslist > div.topic--demo {
        position: absolute;
        top: calc(75% + 30px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .diet_plans_box > .sidebar > div.planslist > div.topic--demo > span {
        text-align: center;
        font-size: 0.9rem;
        color: rgb(0,0,0);
        font-weight: bold;
    }
    .diet_plans_box > .sidebar > div.planslist > div.demo {
        position: absolute;
        top: calc(75% + 50px);
        width: 100%;
        bottom: 0;
    }
    .diet_plans_box > .sidebar > div.planslist > div.demo > div.demo {
        position: absolute;
        top: 10px;
        left: 5%;
        width: 90%;
        min-height: 50px;
        height: 70%;
        background-color: rgb(200,200,200);
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        border-radius: 1rem;
        border: none;

        cursor: pointer;
        transition: all 0.3s;
    }
    .diet_plans_box > .sidebar > div.planslist > div.demo > div.demo img {
        position: absolute;
        top: 5px;
        height: min(25px , 70%);
        width: 22px;
        object-fit: cover;
        opacity: 0.5;
        transition: all 0.3s;
    }
    .diet_plans_box > .sidebar > div.planslist > div.demo > div.demo > p {
        position: absolute;
        top: 30px;
        width: fit-content;
        color: rgb(150,150,150);
        font-size: min(1rem , 5vh);
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        transition: all 0.3s;
    }

    .diet_plans_box > .sidebar > div.planslist > div.mentor {
        position: absolute;
        top:calc(20% + 40px);
        width: 100%;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        direction: ltr;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 2vh;
        padding-bottom: 2vh;
    }
    .diet_plans_box > .sidebar > div.planslist > div.mentor > div.trainee {
        width: 100%;
        height: 50px;
        background-color: transparent;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        border-top: solid 0.1rem rgb(200,200,200);

        cursor: pointer;
        transition: all 0.3s;
    }
    .diet_plans_box > .sidebar > div.planslist > div.mentor > div.trainee > p.trainee--name {
        width: 100%;
        height: 100%;
        width: fit-content;
        color: rgb(50,50,50);
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 0.8rem;
        transition: all 0.3s;
    }
}
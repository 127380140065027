@media (orientation: portrait) {
    div.home {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: white;
        margin: 0;
        overflow-y: auto;
        /* background: linear-gradient(to bottom, rgb(255, 235, 225), rgb(255,255,255));  */
    }

    div.home > img {
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        filter: blur(3px) brightness(100%);
    }

    div.home > div.boxUsers {
        position: absolute;
        top: 15%;
        left: 0%;
        height: 10%;
        width: max(100% , 300px);
        background-color: white;
        /* border: solid 0.1rem rgb(160,160,160); */
        /* border-radius: 1rem; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        direction: rtl;
    }

    div.home > div.boxUsers > p {
        font-size: 1.3rem;
        color: black;
        font-weight: bold;
        opacity: 0;
        transition: all 0.5s;
    }

    div.home > div.boxUsers > p > span {
        font-size: 1.7rem;
        color: rgb(185 , 55 , 20);
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-weight: bold;
        width: 30px;
        text-align: center;

    }

    div.home > div.boxBanners {
        height: 40%;
        top: 25%;
        left: 0;
        right: 0;
        position: absolute;
        /* border-radius: 2rem; */
    }
    div.home > div.boxBanners > img.banner {
        width: 100%;
        /* left: 2%; */
        max-height: 105%;
        min-height: 105%;
        position: absolute;
        top: 0%;
        /* border-radius: 20px; */
        border: solid 0.1rem white;

        transition: all 1s;
    }
    div.home > div.boxBanners > span.sliderLeft {
        height: 10%;
        text-align: center;
        align-items: center;
        position: absolute;
        display: flex;
        font-size: 6vh;
        color: rgb(50, 50, 50);
        top: 47.5%;
        left: 3%;
        cursor: pointer;

        transition: all 0.5s;
    }
    div.home > div.boxBanners > span.sliderRight {
        height: 10%;
        text-align: center;
        align-items: center;
        display: flex;
        position: absolute;
        font-size: 6vh;
        color: rgb(50, 50, 50);
        top: 47.5%;
        right: 3%;
        cursor: pointer;

        transition: all 0.5s;
    }
    div.home > div.boxBanners > span.sliderLeft:active  {
        color: rgb(0,30,0);
    }
    div.home > div.boxBanners > span.sliderRight:active {
        color: rgb(0,30,0);
    }

    div.home > div.boxPartners {
        position: absolute;
        top: 75%;
        left: 0;
        right: 0;
        height: 430px;
    }

    div.home > div.boxPartners > div.topic {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    div.home > div.boxPartners > div.topic > span {
        font-size: 1.5rem;
        color: white;
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 20px rgb(185,55,20);
        font-weight: bold;
    }

    div.home > div.boxPartners > div.partners {
        position: absolute;
        top: 50px;
        height: 330px;
        left: 0;
        right: 0;
        background-color: rgb(30,30,30);
        display: grid;
        grid-template-columns: repeat(2, 140px);
        grid-template-rows: repeat(2, 140px);
        grid-gap: 20px calc((100% - 270px) / 2);
        justify-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 10px;
    } 
    
    div.home > div.boxPartners > div.partners > div.partner {
        position: relative;
        display: inline-block;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 140px;
        border-radius: 1rem;
    }

    /* div.home > div.boxPartners > div.partners {
        position: absolute;
        top: 50px;
        height: 330px;
        left: 0;
        right: 0;
        background-color: rgb(30,30,30);
        display: inline-block;
    }

    div.home > div.boxPartners > div.partners > div.partner {
        position: absolute;
        width: 250px;
        height: 280px;
        left: calc((100% - 250px)/2);
        top: calc((100% - 280px)/2);
        box-shadow: 0px 0px 20px rgb(255,255,255, 0.7);
    } */

    div.home > div.boxPartners > div.partners > div.partner > img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        
        box-shadow: 0px 0px 20px rgb(255,255,255, 0.7);
        filter: brightness(90%);
        opacity: 1;
        transition: all 0.3s;
    }

    div.home > div.boxPartners > div.partners > div.partner > span {
        position: absolute;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        background-color: rgb(20,20,20,0.2);
        box-shadow: 0 0px 20px 3px rgb(185,55,20);
        text-align: center;
        color: white;
        font-size: 1.2rem;
        opacity: 0;
        transition: all 0.3s;
        z-index: 1000;
        font-weight: bold;
    }

    div.home > div.boxPartners > div.all {
        position: absolute;
        top: 380px;
        height: 50px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
    }

    div.home > div.boxPartners > div.all > button {
        width: 100%;
        height: 100%;
        background-color: black;
        font-size: 1.2rem;
        color: white;
        border: none;
    }





    div.home > div.boxDonates {
        position: absolute;
        top: calc(80% + 430px);
        left: 0;
        right: 0;
        height: 350px;
    }

    div.home > div.boxDonates > div.topic {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    div.home > div.boxDonates > div.topic > span {
        font-size: 1.6rem;
        color: rgb(185 , 55 , 20);
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 20px 2px rgb(250,200,170);
        /* font-weight: bold; */
    }

    div.home > div.boxDonates > div.donates {
        position: absolute;
        top: 50px;
        height: 250px;
        left: 0;
        right: 0;
        background-color: rgb(245,240,240);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 5px;

    }

    div.home > div.boxDonates > div.donates > div.donate {
        width: 100%;
        height: 45px;
        display: inline-block;
        border-bottom: solid 0.1rem rgb(250,200,170 , 0.3);
    }

    div.home > div.boxDonates > div.donates > div.donate > span.name {
        position: absolute;
        height: 45px;
        width: 50%;
        right: 0;
        font-size: 1rem;
        color: rgb(100,100,100);
        font-weight: bold;
        border-left: solid 0.1rem rgb(200,200,200,0.5);
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    div.home > div.boxDonates > div.donates > div.donate > span.date {
        position: absolute;
        height: 45px;
        width: 50%;
        left: 0;
        font-size: 1rem;
        color: rgb(100,100,100);
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }



    div.home > div.boxDonates > div.all {
        position: absolute;
        top: 300px;
        height: 50px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        background-color: rgb(245,240,240);
    }

    div.home > div.boxDonates > div.all > button {
        width: 100%;
        height: 100%;
        background-color: white;
        font-size: 1.2rem;
        color: rgb(185 , 55 , 20);
        border: none;
        box-shadow: 0 3px 10px 0px rgb(250,200,170);
    }

    

    div.home > div.boxButtons {
        position: absolute;
        top: calc(85% + 780px);
        height: 100px;
        left: 0%;
        right: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.home > div.boxButtons > button.button {
        background-color: rgb(185,55,20,0.9);
        color: white;
        font-size: clamp(1.2rem , 7vw , 2.5rem);
        border: solid 0.1rem white;
        direction: rtl;
        border-radius: 2rem;
        width: 90%;
        height: 60px;
        transition: 1s all;
    }

    div.home > div.boxButtons > button.button:hover , button.button:active {
        border-color: rgb(0,180,0);
    }

    div.home > div.logos  {
        position: absolute;
        top: calc(85% + 900px);
        display: inline-block;
        width: 100%;
        height: 150px;
        background-color: rgb(30,30,30);
    }

    div.home > div.logos > div.enamad {
        position: absolute;
        top: 30px;
        left: 20px;
        width: 60px;
        height: 80px;
        background-color: rgb(100,100,100);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.home > div.logos > div.enamad > a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.home > div.logos > div.enamad > a > img {
        width: 100%;
        height: 100%;
    }

    div.home > div.logos > div.zarinpal {
        position: absolute;
        top: 30px;
        left: 120px;
        width: 60px;
        height: 80px;
        background-color: rgb(100,100,100);
    }
}

@media (orientation: portrait) {
    .t_diets {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;

        background-color: white;
    }

    .t_diets > .plansbar {
        position: absolute;
        top: 0;
        left: 20px;
        right: 0;
        height: 100%;

        background-color: white;
    }

    .t_diets > .plansbar .plan_section {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: calc(100% - 60px);
        overflow-y: auto;
        overflow-x: hidden;
        background-color: white;
    }

    .t_diets > .plansbar .control_section {
        position: absolute;
        top: calc(100% - 60px);
        left: 0;
        right: 0;
        height: 60px;

        background-color: white;
    }

    .t_diets > .sidebar {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        width: 60%;
        height: 100%;
        background-color: rgb(245,255,245,0.5);
        backdrop-filter: blur(10px);
        box-shadow: 0px 0px 20px rgb(185,55,20);
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        transition: all 0.5s;
        z-index: 999;
    }

    .t_diets > .sidebar > div.control {
        position: absolute;
        right: 0;
        width: 20px;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .t_diets > .sidebar > div.control > img {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        /* box-shadow: 0 0 10px 0 rgb(0,120,0); */
        opacity: 0.7;
    }

    .t_diets > .sidebar > div.planslist {
        position: absolute;
        width: calc(100% - 20px);
        height: 100%;
        left: 0;
        top: 0;
        display: inline-block;
    }

    .t_diets > .sidebar > div.planslist > div.topic {
        position: absolute;
        top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .t_diets > .sidebar > div.planslist > div.topic > span.t1 {
        text-align: center;
        font-size: 2.5rem;
        color: rgb(185,55,20);
        opacity: 0.9;
        font-weight: bold;
    }

    .t_diets > .sidebar > div.planslist > div.topic > span.t2 {
        text-align: center;
        font-size: 1rem;
        color: rgb(185,55,20);
        opacity: 0.9;
        font-weight: bold;
    }

    .t_diets > .sidebar > div.planslist > div.topic > span.t3 {
        margin-top: 25px;
        text-align: center;
        font-size: 0.8rem;
        color: rgb(185,55,20);
        opacity: 0.9;
        /* font-weight: bold; */
    }

    .t_diets > .sidebar > div.planslist > div.topic--plans {
        position: absolute;
        top: calc(20% + 20px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .t_diets > .sidebar > div.planslist > div.topic--plans > span {
        text-align: center;
        font-size: 1rem;
        color: rgb(0,0,0);
        opacity: 0.9;
        font-weight: bold;
    }

    .t_diets > .sidebar > div.planslist > div.plans {
        position: absolute;
        top:calc(20% + 40px);
        width: 100%;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        direction: ltr;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 2vh;
        padding-bottom: 2vh;
    }
    .t_diets > .sidebar > div.planslist > div.plans > div.plan {
        width: 100%;
        height: 50px;
        background-color: transparent;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        border-top: solid 0.1rem rgb(200,200,200);

        cursor: pointer;
        transition: all 0.3s;
    }
    .t_diets > .sidebar > div.planslist > div.plans > div.plan > p.plan--name {
        width: 100%;
        height: 100%;
        width: fit-content;
        color: rgb(50,50,50);
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 0.8rem;
        transition: all 0.3s;
    }
}


@media (orientation: landscape) {
        
    .tpanel--body {
        width: 100%;
        height: 100%;
        background-color: rgb(10, 10, 10);
        margin: 0;
        position: fixed;
    }

    .tpanel--body > div {
        width: 100%;
        height: 100%;
        background-color: rgb(10, 10, 10);
        margin: 0;
        position: fixed;
        display: flex;
        flex-direction: row;
    }

    .tpanel--dashboard {
        margin-top: 2.5%;
        margin-left: 2%;
        margin-right: 5%;
        width: 10%;
        height: 90%;
        border-radius: 25px;
        background-color: white;
        border-right-style:solid;
        border-color: black;
        border-width: 1px;

        display: flex;
        flex-direction: column;
        position: relative;
        left: 0;

        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
        transition: left 1000ms;
    }

    .tpanel--viewboard {
        width: 80%;
        height: 92%;
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        left: 0;
        overflow-y: auto;

        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
        transition: left 1000ms;
    }

    .tpanel--dashboard > a.logo {
        width: 100%;
        height: 15%;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
    }

    .tpanel--dashboard > a.logo img {
        /* height: 100%; */
        min-width: 40%;
        transition: all 0.3s;
        cursor: pointer;
        margin-top: 10%;
    }

    .tpanel--dashboard > a.logo img:hover {
        filter: brightness(40%);
    }

    .tpanel--dashboard > div.task  {
        width: 100%;
        height: 15%;

        border-top-style:solid;
        border-color: black;
        border-width: 1px;
        justify-content: center;
        align-items: center;
        display: flex;
        transition: color 0.2s;
        transition: background-color 0.2s;
    }

    .tpanel--dashboard > div.task:hover  {
        cursor: pointer;
    }

    .tpanel--dashboard > div.task > p  {
        font-size: calc(min(2.5vh , 4vw));
        text-align: center;
        display: flex;
        transition: color 0.2s;
    }

    .tpanel--dashboard > div.cover  {
        width: 100%;
        height: 40%;
        display: flex;
        align-items:end;
        justify-content: flex-end;
        background-image: linear-gradient(to top , rgb(0, 0, 0) , rgb(255, 255, 255));
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
    }

    .tpanel--dashboard > div.cover img {
        filter: brightness(40%) contrast(110%);
        max-width: 100%;
        min-width: 100%;
        max-height: 60%;
        /* box-shadow: 0px 10px 5px #000000; */
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
    }


    .tpanel--viewboard > .diets {
        padding-bottom: 10px;
        margin-left: 3%;
        width: 94%;
        height: 94%;
        display: flex;
        flex-direction: row;
        justify-content: left;

        border-style: solid;
        border-width: 5px;
        border-color: rgb(185, 55, 20);
        border-radius: 15px;
        overflow-x: hidden;
        flex-wrap: wrap;
        overflow-y: auto;

        background-color: rgb(40, 40, 40);
    }

    .tpanel--viewboard > .diets > div.diet {
        margin-top: 5%;
        margin-left: 4%;
        width: 28%;
        height: 20%;
        background-color: black;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        border-width: 2px;
        border-color: #000000;
        border-style: solid;
        font-size: 3vh;
        /* flex-wrap: wrap; */

        cursor: pointer;
        transition: all 0.3s;
    }


    .tpanel--viewboard > .diets > div.diet img {
        width: 100%;
        max-height: 70%;
        min-height: 60%;
        object-fit: cover;
        /* position:absolute; */
        opacity: 0.2;
        border-radius: 15px;
        transition: all 0.3s;
    }

    .tpanel--viewboard > .diets > div.diet > p.diet--name {
        width: 100%;
        max-height:28%;
        min-height: 28%;
        width: fit-content;
        color: white;
        /* font-size: 3vh; */
        text-align: center;
        border-radius: 15px;
        margin: 1%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .tpanel--viewboard > .diets > div.adddiet {
        margin-top: 5%;
        margin-left: 4%;
        width: 28%;
        height: 20%;
        background-color: black;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        border-width: 2px;
        border-color: #000000;
        border-style: solid;
        font-size: 7vh;
        /* flex-wrap: wrap; */
        color: rgb(0, 200, 0);

        cursor: pointer;
        transition: all 0.3s;
    }

    .tpanel--viewboard > .diets > div.adddiet:hover {
        /* background-color: rgb(0 , 200 , 0); */
        border-color: rgb(185, 55, 20);
        /* flex-wrap: wrap; */
        color: rgb(185, 55, 20);

    }

    .tpanel--viewboard > .diets > div.diet--modal {
        left: 17%;
        top: 0;
        display: none;
        visibility: hidden;
        width: 83%;
        height: 100%;
        z-index: 10;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    .tpanel--viewboard > .diets > div.diet--modal > div.modal--dietbox {
        width: 80%;
        height: 80%;
        background-color: rgb(30,30,30);
        color: black;
        position: relative;
        border-radius: 15px;
        opacity: 0;
        overflow: auto;
        transition: all 0.3s;
    }

    .tpanel--viewboard > .diets > div.diet--modal > div.share {
        width: 100%;
        font-size: 2rem;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tpanel--viewboard > .diets > div.diet--modal > div.share button {
        width: 80%;
        height: 90%;
        background-color: rgb(0, 0, 0);
        color: rgb(255, 255, 255);
        font-size: 3vh;
        border: solid rgb(185, 55, 20) 2px;
        border-radius: 20px;

        transition: all 0.3s;
    }

    .tpanel--viewboard > .diets > div.diet--modal > div.share button:hover {
        color: rgb(255, 255, 255);
        border-color: rgb(0,160,0);
    }

    .tpanel--viewboard > .diets > div.shareDiet--modal {
        left: 17%;
        top: 0;
        display: none;
        visibility: hidden;
        width: 83%;
        height: 100%;
        z-index: 10;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    .tpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox {
        width: 60%;
        height: 80%;
        background-color: #000000;
        border: solid 2px rgb(0 , 60 , 0);
        border-radius: 20px;
        display: flex;
        opacity: 0;

        transition: all 0.3s;
    }

    .tpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .tpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button {
        width: 100%;
        height: 50%;
        font-size: 4vh;
        color: white;
        background-color: #000000;
        border: none;

        transition: all 0.3s;
    }

    .tpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button:hover {
        color: rgb(185, 55, 20);
    }

    .tpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button.qrcode {
       border-bottom: solid 1px rgb(185, 55, 20);
       border-top-left-radius: 20px;
       border-top-right-radius: 20px;
    }

    .tpanel--viewboard > .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet button.link {
        border-top: solid 1px rgb(185, 55, 20);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
     }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > .Qr {
        width: 50%;
        height: 50%;
        border: solid 1px rgb(185, 55, 20);
        border-radius: 20px;
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > .back {
        width: 30%;
        height: 10%;
        margin-top: 10%;
        font-size: 3vh;
        border: solid 2px white;
        border-radius: 15px;
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        direction: rtl;
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .topic {
        width: 100%;
        height: 10%;
        font-size: 3vh;
        color: white;
        text-align: center;
        margin-top: 10%;
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .link {
        width: 100%;
        height: 10%;
        font-size: 2vh;
        color: rgb(185, 55, 20);
        text-align: center;
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .phoneTopic {
        width: 100%;
        height: 10%;
        font-size: 2.5vh;
        color: white;
        text-align: center;
        margin-top: 10%;
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .phone {
        width: 60%;
        height: 8%;
        font-size: 2vh;
        color: black;
        text-align: center;
        border: 2px solid rgb(185, 55, 20);
        border-radius: 20px;
        margin-right: 20%;
        margin-left: 20%;
        margin-top: -3%;
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .send {
        width: 30%;
        height: 8%;
        font-size: 2vh;
        color: white;
        background-color: black;
        border: solid 1px white;
        text-align: center;
        border-radius: 15px;
        margin-right: 35%;
        margin-top: 2%;

        transition: all 0.3s;
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .send:hover {
        color: rgb(0 , 150 , 0);
        border-color: rgb(0 , 150 , 0);
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .back {
        width: 100%;
        height: 8%;
        font-size: 2vh;
        color: white;
        background-color: black;
        border: solid 1px rgb(0 , 60 , 0);
        text-align: center;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        margin-top: auto;

        transition: all 0.3s;
    }

    .tpanel--viewboard >  .diets > div.shareDiet--modal > div.modal--shareDietbox > div.shareDiet > div.linkbox .back:hover {
        color: rgb(185, 55, 20);
        border-color: rgb(185, 55, 20);
    }




    .commingsoon {
        background-color: black;
        color: white;
        width: 40%;
        height: 20%;
        position: absolute;
        left: 30%;
        top: 40%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .commingsoon span {
        font-size: 4vh;
        text-align: center;
        width: 100%;
        direction: rtl;

    }

    .profile {
        width: 50%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
    }
}
@media (orientation: landscape) {
    .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,1); /* Black w/ opacity */
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border-radius: 15px; */
        /* position: absolute; */
        align-items: center;
    }

    .loading img {
        width: 15%;
        max-width: 25%;
        /* -webkit-animation:light 1.5s cubic-bezier(.0, .5, .5, 1) infinite;
        -moz-animation:light 1.5s cubic-bezier(.0, .5, .5, 1) infinite;
        animation:light 1.5s cubic-bezier(.0, .5, .5, 1) infinite; */
        -webkit-animation:lightning 2.2s cubic-bezier(.0, .5, .5, 1)  infinite;
        -moz-animation:lightning 2.2s cubic-bezier(.0, .5, .5, 1) infinite;
        animation:lightning 2.2s cubic-bezier(.0, .5, .5, 1) infinite;
    }
    @-moz-keyframes spin { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }

    @-moz-keyframes lightning { 
        50% { opacity: 0; filter: brightness(30%);} 
    }
    @-webkit-keyframes lightning { 
        50% { opacity: 0; filter: brightness(30%);} 
    }
    @keyframes lightning { 
        50% { opacity: 0; filter: brightness(30%);} 
    }

    .loading p {
        width: 60%;
        height: 30%;
        font-size: 2rem;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}


@media (orientation: landscape) {

    .pdf--body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-items: center;
        overflow-y: scroll;
        overflow-x: scroll;
        height: 100%;
        zoom: 0.5;
    }

    .pdf--page {
        width: 210mm;
        height: 297mm;
        display: flex;
        justify-content: center;
        justify-items: center;
        justify-items: center; 
        overflow-y: scroll;
        position: absolute;
        direction: rtl;
        background-color: rgb(200,200,200);
        border: solid 0.1rem black;
    }

    .pdf--page > img.pagecover {
        height: 297mm;
        width: 210mm;
        filter: brightness(15%) blur(8px);
        z-index: 1;
        position: inherit;
        display: none;
    }

    .pdf--page > div.diet--info {
        position: absolute;
        top: 5mm;
        height: 70mm;
        right: 5mm;
        left: 5mm;
        background-color: rgb(20,20,20);
        z-index: 2;
        border-radius: 1rem;
        /* opacity: 0.2; */
        border: solid 0.1rem rgb(0,120,0);
    }

    .pdf--page > div.diet--info > div.diet--name {
        position: absolute;
        top: 0mm;
        height: 15mm;
        right: 0mm;
        left: 0mm;
        background-color: rgb(0 , 0 , 0);
        border-radius: 1rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        display: flex;
    }

    .pdf--page > div.diet--info > div.diet--name span {
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.6rem;
        text-align: center;
        color: white;
    }

    .pdf--page > div.diet--info > div.coach--sign {
        position: absolute;
        top: 20mm;
        height: 30mm;
        left: 5mm;
        width: 50mm;
        background-color: rgb(0 , 0 , 0);
        border-radius: 1rem;
        border: dashed 0.1rem rgb(185, 55, 20);
    }

    .pdf--page > div.diet--info > div.coach--name {
        position: absolute;
        top: 55mm;
        bottom: 5mm;
        left: 5mm;
        width: 50mm;
        background-color: rgb(0 , 0 , 0);
        border-radius: 1rem;
        border: dashed 0.1rem rgb(185, 55, 20);
        display: flex;
    }

    .pdf--page > div.diet--info > div.coach--name span {
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.2rem;
        text-align: center;
        color: white;
    }

    .pdf--page > div.diet--info > div.details {
        position: absolute;
        right: 5mm;
        width: 95mm;
        top: 20mm;
        bottom: 5mm;
        background-color: black;
        border: solid 0.1rem rgb(185, 55, 20);
        border-radius: 1rem;
    }

    .pdf--page > div.diet--info > div.details > div.user--name {
        position: relative;
        top: 0mm;
        height: 15mm;
        right: 0mm;
        left: 0mm;
        background-color: rgb(0 , 0 , 0);
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    
        display: flex;
    }

    .pdf--page > div.diet--info > div.details > div.user--name span {
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.2rem;
        text-align: right;
        padding-right: 0.5rem;
        color: white;
    }

    .pdf--page > div.diet--info > div.details > div.meals--count {
        position: relative;
        top: 0mm;
        height: 15mm;
        right: 0mm;
        left: 0mm;
        background-color: rgb(0 , 0 , 0);
        
        display: flex;
    }

    .pdf--page > div.diet--info > div.details > div.meals--count span {
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.2rem;
        text-align: right;
        padding-right: 0.5rem;
        color: white;
    }

    .pdf--page > div.diet--info > div.details > div.date--created {
        position: relative;
        top: 0mm;
        height: 14mm;
        right: 0mm;
        left: 0mm;
        background-color: rgb(0 , 0 , 0);
        /* border-radius: 1rem; */
        /* border-left: solid 0.1rem rgb(185, 55, 20); */
        /* border-top: solid 0.1rem rgb(185, 55, 20); */
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        display: flex;
    }

    .pdf--page > div.diet--info > div.details > div.date--created span {
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.2rem;
        text-align: right;
        padding-right: 0.5rem;
        color: white;
    }

    .pdf--page > div.diet--ad {
        position: absolute;
        top: 80mm;
        height: 20mm;
        right: 5mm;
        left: 5mm;
        background-color: rgb(20,20,20);
        z-index: 2;
        border-radius: 1rem;
        /* opacity: 0.2; */
        color: rgb(185, 55, 20);
        border: solid 0.1rem rgb(0,120,0);
        display: flex;
    }

    .pdf--page > div.diet--ad > .logo {
        width: 50%;
        height: 100%;
        display: flex;
    }
    .pdf--page > div.diet--ad > .logo img {
        position: absolute;
        height: 130%;
        top: -5%;
        left: 0;
        /* width: 12%; */
    }
    .pdf--page > div.diet--ad > .web {
        width: 50%;
        height: 100%;
       
    }
    .pdf--page > div.diet--ad > .web span {
        position: absolute;
        height: 100%;
        right: 5mm;
        text-align: right;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.5rem;
    }
    

    .pdf--page > div.mealbox {
        position: absolute;
        top: 85mm;
        height: 100mm;
        right: 5mm;
        left: 5mm;
        z-index: 2;
    }

    .pdf--page > div.mealbox > div.index {
        position: absolute;
        top: 0mm;
        height: 100mm;
        right: 0mm;
        width: 10mm;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        border-radius: 1rem;
        border: solid 0.1rem rgb(185, 55, 20);
        z-index:2;
    }

    .pdf--page > div.mealbox > div.index span {
        color: white;
        font-size: 1.5rem;
        writing-mode: vertical-rl;
    }

    .pdf--page > div.mealbox > div.meal {
        position: absolute;
        top: 0mm;
        height: 100mm;
        right: 15mm;
        left: 0mm;
        background-color: rgb(20,20,20);
        z-index: 2;
        opacity: 0.9;
        border-radius: 2rem;
        border: solid 0.1rem rgb(185, 55, 20);
    }

    .pdf--page > div.mealbox > div.meal > div.meal--name {
        position: absolute;
        right: 30mm;
        left: 30mm;
        top: 3mm;
        height: 10mm;
        background-color: rgb(240, 240, 220);
        border:solid 0.1rem rgb(0 , 30 , 0);
        border-radius: 1rem;
        display: flex;
    }

    .pdf--page > div.mealbox > div.meal > div.meal--name span {
        color: black;
        height: 100%;
        width: 100%;
        font-size: 1.7rem;
        line-height: 2rem;
        text-align: center;
    }

    .pdf--page > div.mealbox > div.meal > div.meal--info {
        position: absolute;
        right: 5mm;
        left: 5mm;
        top: 20mm;
        height: 60mm;
        border:solid 0.1rem rgb(0 , 30 , 0);
        background-color: rgb(150,150,150);
        border-radius: 0.5rem;
    }

    .pdf--page > div.mealbox > div.meal > div.meal--info > div.headers {
        position: absolute;
        top: 0mm;
        right: 0mm;
        left: 0mm;
        height: 10mm;
        background-color: rgb(240, 190, 175);
        border-radius: 0.5rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        display: flex;
        flex-direction: row;
    } 

    .pdf--page > div.mealbox > div.meal > div.meal--info > div.headers > div.ingredients {
        width: 122.5mm;
        display: flex;
        border-left: solid 0.05rem rgb(255, 255, 255);
    } 
    .pdf--page > div.mealbox > div.meal > div.meal--info > div.headers > div.ingredients span {
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    } 
    .pdf--page > div.mealbox > div.meal > div.meal--info > div.headers > div.recip {
        width: 52.5mm;
        display: flex;
        border-right: solid 0.05rem rgb(255, 255, 255);
    } 
    .pdf--page > div.mealbox > div.meal > div.meal--info > div.headers > div.recip span {
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    } 

    .pdf--page > div.mealbox > div.meal > div.meal--info > div.info {
        position: absolute;
        top: 10mm;
        right: 0mm;
        left: 0mm;
        height: 50mm;
        display: flex;
        flex-direction: row;
    } 

    .pdf--page > div.mealbox > div.meal > div.meal--info > div.info > div.ingredients {
        width: 122.5mm;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content:flex-start;
        border-left: solid 0.05rem rgb(255, 255, 255);
    } 

    .pdf--page > div.mealbox > div.meal > div.meal--info > div.info > div.ingredients > div.ingredients--item {
        width: 44%;
        /* min-width: 53mm; */
        margin-right: 4%;
        min-height: 15%;
        height: fit-content;
        /* max-width: 53mm; */
        display: flex;
        justify-content: center;
        border: solid 0.1rem rgb(185, 55, 20);
        background-color: rgb(240,240,240);
        border-radius: 0.5rem;
        /* margin: 2.4mm; */
        margin-top: 2mm;
        margin-bottom: 2mm;
    } 

    .pdf--page > div.mealbox > div.meal > div.meal--info > div.info > div.ingredients > div.ingredients--item span {
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 0.9rem;
    } 

    .pdf--page > div.mealbox > div.meal > div.meal--info > div.info > div.recip {
        width: 52.5mm;
        display: flex;
        border-right: solid 0.05rem rgb(255, 255, 255);
        display: flex;
        justify-content: flex-start;
    } 

    .pdf--page > div.mealbox > div.meal > div.meal--info > div.info > div.recip span {
        width: 90%;
        margin-top: 2mm;
        margin-bottom: 2mm;
        margin-right: 2.5mm;
        white-space: pre-line;
        height: fit-content;
        font-size: 0.9rem;
        text-align: right;
        color: rgb(30,30,30);
        font-weight: bold;
    } 

    .pdf--page > div.mealbox > div.meal > div.meal--analysis {
        position: absolute;
        right: 5mm;
        left: 5mm;
        top: 85mm;
        height: 10mm;
        border:solid 0.1rem rgb(0 , 30 , 0);
        border-radius: 0.5rem;

        display: flex;
        justify-content: flex-start;
        flex-direction: row;
    }

    .pdf--page > div.mealbox > div.meal > div.meal--analysis > div {
        width: 25%;
        height: 100%;
        background-color: rgb(240, 240, 220);
        color: black;
        display: flex;
    }

    .pdf--page > div.mealbox > div.meal > div.meal--analysis > div.calories {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-left: solid 0.05rem black;
    }

    .pdf--page > div.mealbox > div.meal > div.meal--analysis > div.fat {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-right: solid 0.05rem black;
    }

    .pdf--page > div.mealbox > div.meal > div.meal--analysis > div.protein {
        border-left: solid 0.05rem black;
        border-right: solid 0.05rem black;
    }

    .pdf--page > div.mealbox > div.meal > div.meal--analysis > div.carbo {
        border-left: solid 0.05rem black;
        border-right: solid 0.05rem black;
    }

    .pdf--page > div.mealbox > div.meal > div.meal--analysis > div > span.tag {
        width: fit-content;
        height: 100%;
        text-align: right;
        padding-right: 0.5rem;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.1rem;
        color: rgb(0, 0, 30);
    }

    .pdf--page > div.mealbox > div.meal > div.meal--analysis > div > span.text {
        max-width: 50%;
        margin-right: 5%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1rem;
    }

}

@media (orientation: portrait) {
    .cpanel--viewboard > div.profile {
        position: absolute;
        left: 1%;
        right: 1%;
        height: 98%;
        border-radius: 15px;
        overflow: hidden;
        background-color: rgb(20,20,20);
    }

    .cpanel--viewboard > div.profile > div.interface {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 150px;
    }

    .cpanel--viewboard > div.profile > div.controlbox {
        position: absolute;
        top: 170px;
        min-height: 150px;
        height: 40%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
    }

    .cpanel--viewboard > div.profile > div.controlbox > div.box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cpanel--viewboard > div.profile > div.controlbox > div.box > div.inner--box {
        width: 90%;
        height: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        direction: rtl;
        border-radius: 2rem;
        border: 1px solid rgb(255,255,255,0.3);
        transition: all 0.5s;
    }
    .cpanel--viewboard > div.profile > div.controlbox > div.box > div.inner--box > img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        filter: invert(50%);
        cursor: pointer;
        transition: all 0.5s;
    }

    .cpanel--viewboard > div.profile > div.controlbox > div.box > div.inner--box > img:hover , img:active {
        filter: invert(100%);
    }
    .cpanel--viewboard > div.profile > div.controlbox > div.box > div.inner--box:hover , div.inner--box:active {
        background-color: rgb(255,255,255,0.3);
    }

    .cpanel--viewboard > div.profile > div.controlbox > div.box > div.inner--box > span {
        font-size: 1rem;
        color: rgb(255,255,255,0.9);
        font-weight: bold;
    }

    .cpanel--viewboard > div.profile > div.partnerbox {
        position: absolute;
        width: 80%;
        height: 15%;
        top: calc(170px + max(40% , 150px) + 20px);
        left: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        box-shadow: 0px 0px 5px rgb(185,55,20,0.8);
        background-color: rgb(0,0,0,0.5);
        color: white;
        font-size: 2rem;

        transition: all 0.5s;
    }

    .cpanel--viewboard > div.profile > div.partnerbox:hover , div.partnerbox:active {
        background-color: rgb(255,255,255,0.6);
    }
}
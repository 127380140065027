@media (orientation: portrait) {
    div.trainee_desk {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;

        background-color: white;
    }

    div.trainee_desk > div.controlbox {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    div.trainee_desk > div.controlbox > button.back {
        height: 45px;
        width: 15%;
        background-color: rgb(0,0,0);
        border: solid 0.1rem black;
        color: white;
        font-size: 1.5rem;
        border-radius: 1rem;
        transition: all 0.3s;
    }
    div.trainee_desk > div.controlbox > button.back:hover , button.back:active{
        background-color: white;
        color: rgb(180,0,0);
        border-color: rgb(180,0,0);
    }
    div.trainee_desk > div.controlbox > div.topic span {
        color: rgb(185,55,20);
        font-weight: bold;
        font-size: 1.3rem;
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        text-shadow: 4px 4px 20px rgba(0,0,0,0.7);
    }

    div.trainee_desk > div.desk {
        position: absolute;
        top: 10%;
        left: 0;
        height: 90%;
        width: 100%;
        display: inline-block;
    }

    div.trainee_desk > div.desk > div.info {
        position: absolute;
        top: 30px;
        left: 0;
        height: calc(50% - 30px);
        min-height: 200px;
        width: 100%;
        display: inline-block;
        box-shadow: 0px 0px 30px 0px rgb(185,55,20 , 0.8);
    }
    div.trainee_desk > div.desk > div.info > div.diet_count {
        position: absolute;
        top: calc((100% - 150px) / 4);
        right: 20px;
        width: 120px;
        height: 50px;
        border-radius: 1rem;
        background-color: rgb(230,240,230);
        display: inline-block;
        box-shadow: 0px 0px 5px 0px rgb(0,0,0 ,0.8);
    }
    div.trainee_desk > div.desk > div.info > div.diet_count > span.t1 {
        position: absolute;
        height: 100%;
        left: 10px;
        font-size: 1rem;
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    div.trainee_desk > div.desk > div.info > div.diet_count > span.t2 {
        position: absolute;
        height: 100%;
        right: 10px;
        font-size: 1rem;
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    div.trainee_desk > div.desk > div.info > div.workout_count {
        position: absolute;
        top: calc((100% - 150px) / 4);
        left: 20px;
        width: 120px;
        height: 50px;
        border-radius: 1rem;
        background-color: rgb(230,240,230);
        box-shadow: 0px 0px 5px 0px rgb(0,0,0 ,0.8);
        display: inline-block;
    }
    div.trainee_desk > div.desk > div.info > div.workout_count > span.t1 {
        position: absolute;
        height: 100%;
        left: 10px;
        font-size: 1rem;
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    div.trainee_desk > div.desk > div.info > div.workout_count > span.t2 {
        position: absolute;
        height: 100%;
        right: 10px;
        font-size: 1rem;
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    div.trainee_desk > div.desk > div.info > div.last_diet {
        position: absolute;
        top: calc((100% - 150px) / 2 + 50px);
        right: 20px;
        left: 20px;
        height: 50px;
        border-radius: 1rem;
        background-color: rgb(230,240,230);
        box-shadow: 0px 0px 5px 0px rgb(0,0,0 ,0.8);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        direction: rtl;
    }
    div.trainee_desk > div.desk > div.info > div.last_diet > span.t0 {
        height: 100%;
        width: 30%;
        font-size: clamp(0.9rem , 4vw , 1rem);
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-left: 0.1rem solid white;
    }
    div.trainee_desk > div.desk > div.info > div.last_diet > span.t1 {
        width: 34%;
        font-size: clamp(0.9rem , 4vw , 1rem);
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-left: 0.1rem solid white;
    }
    div.trainee_desk > div.desk > div.info > div.last_diet > span.t2 {
        width: 34%;
        font-size: clamp(0.9rem , 4vw , 1rem);
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    div.trainee_desk > div.desk > div.info > div.last_workout {
        position: absolute;
        top: calc(3 * (100% - 150px) / 4 + 100px);
        right: 20px;
        left: 20px;
        height: 50px;
        border-radius: 1rem;
        background-color: rgb(230,240,230);
        box-shadow: 0px 0px 5px 0px rgb(0,0,0 ,0.8);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        direction: rtl;
    }
    div.trainee_desk > div.desk > div.info > div.last_workout > span.t0 {
        width: 30%;
        font-size: clamp(0.9rem , 4vw , 1rem);
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-left: 0.1rem solid white;
    }
    div.trainee_desk > div.desk > div.info > div.last_workout > span.t1 {
        width: 34%;
        font-size: clamp(0.9rem , 4vw , 1rem);
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-left: 0.1rem solid white;
    }
    div.trainee_desk > div.desk > div.info > div.last_workout > span.t2 {
        width: 34%;
        font-size: clamp(0.9rem , 4vw , 1rem);
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }




    div.trainee_desk > div.desk > div.controls {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 50%;
        min-height: 150px;
        display: inline-block;
    }
    div.trainee_desk > div.desk > div.controls > div.gymibox {
        top: 30%;
        right: calc((100% - 300px) / 4);
        width: 100px;
        height: 80px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    div.trainee_desk > div.desk > div.controls > div.gymibox > div.box {
        width: 100%;
        height: 100%;
        background-color: rgb(240,230,230);
        border-radius: 1rem;
        display: inline-block;
        box-shadow: 0px 0px 5px 0px rgb(0,120,0);
        cursor: pointer;
    }
    div.trainee_desk > div.desk > div.controls > div.gymibox > div.box > img {
        position: absolute;
        top: 5px;
        left: 15px;
        width: 70px;
        height: 70px;
        opacity: 0.2;
        filter: blur(1px) brightness(1%);
    }
    div.trainee_desk > div.desk > div.controls > div.gymibox > div.box > span {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.4rem;
        color: rgb(185,55,20,1);
        font-weight: bold;
    }

    div.trainee_desk > div.desk > div.controls > div.dietbox {
        top: 30%;
        right: calc((100% - 300px) / 2 + 100px);
        width: 100px;
        height: 80px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    div.trainee_desk > div.desk > div.controls > div.dietbox > div.box {
        width: 100px;
        height: 80px;
        background-color: rgb(240,230,230);
        border-radius: 1rem;
        display: inline-block;
        box-shadow: 0px 0px 5px 0px rgb(0,120,0);
        cursor: pointer;
    }
    div.trainee_desk > div.desk > div.controls > div.dietbox > div.box > img {
        position: absolute;
        top: 5px;
        left: 15px;
        width: 70px;
        height: 70px;
        opacity: 0.2;
        filter: blur(1px);
        
    }
    div.trainee_desk > div.desk > div.controls > div.dietbox > div.box > span {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.4rem;
        color: rgb(185,55,20,1);
        font-weight: bold;
    }

    div.trainee_desk > div.desk > div.controls > div.workoutbox {
        top: 30%;
        left: calc((100% - 300px) / 4);
        width: 100px;
        height: 80px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    div.trainee_desk > div.desk > div.controls > div.workoutbox > div.box {
        width: 100px;
        height: 80px;
        background-color: rgb(240,230,230);
        border-radius: 1rem;
        display: inline-block;
        box-shadow: 0px 0px 5px 0px rgb(0,120,0);
        cursor: pointer;
    }
    div.trainee_desk > div.desk > div.controls > div.workoutbox > div.box > img {
        position: absolute;
        top: 5px;
        left: 15px;
        width: 70px;
        height: 70px;
        opacity: 0.2;
        filter: blur(1px);
    }
    div.trainee_desk > div.desk > div.controls > div.workoutbox > div.box > span {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.4rem;
        color: rgb(185,55,20,1);
        font-weight: bold;
    }
}

@media (orientation: portrait) {
    div.profile--user {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        top: 0;
        right: 0;
        background-color: white;
    }

    div.profile--user div.profileimage {
        top: 0;
        left: 0;
        width: 100%;
        height: 250px;
        display: inline-block;
        /* justify-content: space-around;
        flex-direction: column; */
        position: absolute;
        background-color: rgb(10,10,10);
    }

    div.profile--user  div.profileimage > div.imageframe {
        position: absolute;
        top: 10px;
        left: calc(50% - 90px);
        width: 180px;
        height: 180px;
        background-color: rgb(185,55,20);
        border: solid 0.1rem rgb(0,50,0);
        border-radius: 10px;
    }

    div.profile--user  div.profileimage > div.imageframe img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0%;
        top: 0%;
        border-radius: 10px;
        object-fit: cover;
    }

    div.profile--user  div.profileimage > button {
        position: absolute;
        top: 200px;
        left: 0;
        right: 0;
        height: 50px;
        background-color: rgb(0,30,0);
        border: solid 0.1rem black;
        color: white;
        font-size: 1rem;

        transition: all 0.5s;
    }

    div.profile--user  div.profileimage > button:hover , button:active {
        background-color: rgb(185, 55, 20);
    }


    div.profile--user > div.info {
        top: 280px;
        height: 120px;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        direction: rtl;
    }

    div.profile--user > div.info > div.namebox {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    div.profile--user > div.info > div.namebox > input {
        width: 60%;
        background-color: rgb(200,200,200);
        color: black;
        outline: none;
        height: 70%;
        border: none;
        border-radius: 0.5rem;
    }

    div.profile--user > div.info > div.namebox > button {
        width: 20%;
        height: 70%;
        background-color: rgb(0,80,0);
        border: none;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        color: white;

        transition: all 0.3s;
    }

    div.profile--user > div.info > div.namebox > button:hover , button:active {
        background-color: rgb(185, 55, 20);
    }

    div.profile--user > div.info > div.usernamebox {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    div.profile--user > div.info > div.usernamebox > input {
        width: 60%;
        background-color: rgb(200,200,200);
        color: black;
        outline: none;
        height: 70%;
        border: none;
        border-radius: 0.5rem;
    }

    div.profile--user > div.info > div.usernamebox > button {
        width: 20%;
        height: 70%;
        background-color: rgb(0,80,0);
        border: none;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        color: white;

        transition: all 0.3s;
    }

    div.profile--user > div.info > div.usernamebox > button:hover , button:active {
        background-color: rgb(185, 55, 20);
    }

    div.profile--user > div.info > div.phonebox {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    div.profile--user > div.info > div.phonebox > input {
        width: 60%;
        background-color: rgb(200,200,200);
        color: black;
        outline: none;
        height: 70%;
        border: none;
        border-radius: 0.5rem;
    }

    div.profile--user > div.info > div.phonebox > button {
        width: 20%;
        height: 70%;
        background-color: rgb(0,80,0);
        border: none;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        color: white;

        transition: all 0.3s;
    }

    div.profile--user > div.info > div.phonebox > button:hover , button:active {
        background-color: rgb(185, 55, 20);
    }

    div.profile--user > div.controlbox {
        top: 400px;
        height: calc(100% - 400px - 40px);
        right: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        direction: rtl;
    }

    div.profile--user > div.controlbox > button {
        width: 80px;
        height: 40px;
        background-color: rgb(250 , 245 , 230);
        color: rgb(50,50,50);
        border: none;
        border-radius: 1rem;
        font-size: 0.8rem;
    }

    div.profile--user > div.deleteaccount {
        position: absolute;
        bottom: 0;
        height: 40px;
        width: 100%;
        display: flex;
    }
    div.profile--user > div.deleteaccount > button {
        width: 100%;
        height: 100%;
        color: white;
        background-color: rgb(100,0,0);
    }



   




    /* div.profile--sign {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
    }

    div.profile--sign > div.topic {
        position: absolute;
        top: 10%;
        left: 10%;
        right: 10%;
        height: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    div.profile--sign > div.topic > span.topic {
        width: 100%;
        height: 100%;
        font-size: 1rem;
        color: white;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    }

    div.profile--sign > div.canvas {
        position: absolute;
        top: 30%;
        left: 5%;
        right: 5%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 1rem;
    }

    div.profile--sign > div.canvas > canvas {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
    }

    div.profile--sign > button.clear {
        position: absolute;
        top: 75%;
        right: 5%;
        width: 40%;
        height: 10%;
        background-color: black;
        color: white;
        font-size: 1.5rem;
        border: solid 0.1rem black;
        border-radius: 1rem;

        transition: all 0.5s;
    }

    div.profile--sign > button.clear:hover , button.clear:active {
        border-color: rgb(185, 55, 20);
    }

    div.profile--sign > button.send {
        position: absolute;
        top: 75%;
        left: 5%;
        width: 40%;
        height: 10%;
        background-color: black;
        color: white;
        font-size: 1.5rem;
        border: solid 0.1rem black;
        border-radius: 1rem;

        transition: all 0.5s;
    }

    div.profile--sign > button.send:hover , button.send:active {
        border-color: rgb(0,60,0);
    }

    div.profile--sign > button.back {
        position: absolute;
        top: 89%;
        left: 5%;
        right: 5%;
        height: 9%;
        background-color: black;
        color: white;
        font-size: 1.5rem;
        border: solid 0.1rem black;
        border-radius: 1rem;

        transition: all 0.5s;
    }

    div.profile--sign > button.back:hover , button.back:active {
        border-color: rgb(30,0,0);
        color: rgb(185, 55, 20);
    } */
}
@media (orientation: portrait) {
    .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background-color: rgb(0,0,0); Fallback color */
        background-color: rgba(250,250,250,0.9); /* Black w/ opacity */
        /* background-color: transparent; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border-radius: 15px; */
        /* position: absolute; */
        align-items: center;
        z-index: 10003;
        opacity: 0;
        transition: all 0.5s;
    }

    .loading.fade-in {
        opacity: 1; /* Fully visible */
    }
    
    .loading.fade-out {
        opacity: 0; /* Fully hidden */
    }

    .loading img {
        width: 30%;
        max-width: 45%;
        /* -webkit-animation:spin 1.5s cubic-bezier(.0, .5, .5, 1) infinite;
        -moz-animation:spin 1.5s cubic-bezier(.0, .5, .5, 1) infinite;
        animation:spin 1.5s cubic-bezier(.0, .5, .5, 1) infinite; */
        -webkit-animation:lightning 2.2s cubic-bezier(.0, .5, .5, 1)  infinite;
        -moz-animation:lightning 2.2s cubic-bezier(.0, .5, .5, 1) infinite;
        animation:lightning 2.2s cubic-bezier(.0, .5, .5, 1) infinite;
        
    }
    @-moz-keyframes spin { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }
    @-moz-keyframes lightning { 
        50% { opacity: 0; filter: brightness(30%);} 
    }
    @-webkit-keyframes lightning { 
        50% { opacity: 0; filter: brightness(30%);} 
    }
    @keyframes lightning { 
        50% { opacity: 0; filter: brightness(30%);} 
    }

    .loading p {
        width: 80%;
        height: 30%;
        font-size: 2rem;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: rgba(52, 152, 239, 1);
        background-color: rgba(52, 122, 170, 0.2);
        border-radius: 1rem;
        transition: all 0.3s;
        direction: rtl;
    }
}

@media (orientation: portrait) {
    .tpanel--viewboard > div.t_profile {
        position: absolute;
        left: 1%;
        right: 1%;
        height: 98%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;

        border-radius: 15px;
        overflow: hidden;

        background-color: white;
    }

    .tpanel--viewboard > div.t_profile > div.interface {
        width: 100%;
        height: 45%;
    }

    .tpanel--viewboard > div.t_profile > div.partnerbox {
        width: 80%;
        height: 15%;
        margin-left: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        box-shadow: 0px 0px 20px rgb(185,55,20,0.5);
    }

    .tpanel--viewboard > div.t_profile > div.controlbox {
        width: 100%;
        height: 35%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .tpanel--viewboard > div.t_profile > div.controlbox > div.box {
        width: 30%;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .tpanel--viewboard > div.t_profile > div.controlbox > div.box > button {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: rgb(250 , 245 , 230);
        border: none;
        cursor: pointer;
        transition: all 0.5s;
        background-size:20px; /* Cover the entire button */
        background-position: center; /* Center the image */
        background-repeat: no-repeat;
    }

    .tpanel--viewboard > div.t_profile > div.controlbox > div.box > button:hover , button:active {
        background-color: rgb(240,255,230);
    }

    .tpanel--viewboard > div.t_profile > div.controlbox > div.box > span {
        font-size: 0.8rem;
        color: rgb(100,100,100);
    }
    
}
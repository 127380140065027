
@media (orientation: portrait) {
    .userinterface {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 150px;
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.8); /* Dark background with transparency */
        box-shadow: 0px 0px 40px rgb(185,55,20,0.8);
    }

    .userinterface > div.background--image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: blur(8px)  brightness(0.2);
    }

    .userinterface > div.background--image > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .userinterface > div.image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100px;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .userinterface > div.image > img {
        width: 70px;
        height: 70px;
        border-radius: 30px;
        box-shadow: 0px 0px 10px rgb(255,255,255,0.8);
        filter: brightness(0.8);
    }

    .userinterface > div.image > span {
        font-size: 0.6rem;
        color: white;
        font-weight: bold;
        width: 70px;
        height: 70px;
        border-radius: 30px;
        box-shadow: 0px 0px 10px rgb(255,255,255,0.8);
        text-align: center;
        align-content: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .userinterface > div.info {
        position: absolute;
        left: 100px;
        top: 0px;
        height: 100px;
        width: calc(100% - 100px);
    }

    .userinterface > div.info > span.username {
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: center;
        align-content: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: clamp(1.3rem , 7vw , 2rem);
        color: rgb(255,255,255,0.9);
        font-weight: bold;
        letter-spacing: 2px;
    }

    .userinterface > div.details {
        position: absolute;
        top: 100px;
        height: 50px;
        width: 100%;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: center;
        direction: rtl;
    }

    .userinterface > div.details > span.name {
        width: 100px;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        color: white;
        direction: rtl;
    }

    .userinterface > div.details > p.trainee {
        width: 70px;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        color: rgb(255,255,255,0.9);
        direction: rtl;
        opacity: 0.8;
    }

    .userinterface > div.details > p.plan {
        width: 70px;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        color: white;
        direction: rtl;
        opacity: 0.8;
    }

    .userinterface > div.details > p > span {
        margin-left: 5px;
        margin-right: 5px;
        color: rgb(100,200,100);
        opacity: 0.5;
    }
}
@media (orientation: portrait) {
    .gymi_support {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        background-color: rgb(200, 200, 200);
    }

    .gymi_support > div.controlbox {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .gymi_support > div.controlbox > button.back {
        height: 45px;
        width: 15%;
        background-color: rgb(0,0,0);
        border: solid 0.1rem black;
        color: white;
        font-size: 1.5rem;
        border-radius: 1rem;
        transition: all 0.3s;
    }
    .gymi_support > div.controlbox > button.back:hover , button.back:active{
        background-color: white;
        color: rgb(180,0,0);
        border-color: rgb(180,0,0);
    }
    .gymi_support > div.controlbox > div.topic {
        width: 70%;
        height: 45px;
        background-color: rgb(0,0,0);
        border: solid 0.1rem rgb(185, 55, 20);
        color: rgb(120,120,120);
        font-size: 1.5rem;
        border-radius: 1rem;
    }
    .gymi_support > div.controlbox > div.topic span {
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
    }

    .gymi_support > div.chats {
        height: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .gymi_support > div.chats > div.topic--demo {
        margin-top: 5vh;
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gymi_support > div.chats > div.topic--demo span {
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 2rem;
        color: rgb(50,50,50);
        opacity: 0.5;
    }
    .gymi_support > div.chats > div.demo {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .gymi_support > div.chats > div.demo > div.chat {
        width: 60%;
        height: 90%;
        background-color: rgb(210,210,210);
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        border-radius: 2rem;
        border: solid rgb(50,50,50) 0.1rem;
        font-size: 2vh;
        /* flex-wrap: wrap; */

        cursor: pointer;
        transition: all 0.3s;
    }
    .gymi_support > div.chats > div.demo > div.chat img {
        margin-top: 5%;
        height: 40%;
        object-fit: cover;
        opacity: 0.5;
        transition: all 0.3s;
    }
    .gymi_support > div.chats > div.demo > div.chat > p.chat--name {
        width: 100%;
        height: 40%;
        width: fit-content;
        color: rgb(150,150,150);
        font-size: 1.5rem;
        text-align: center;
        margin: 1%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        transition: all 0.3s;
    }

    .gymi_support > div.chats > div.topic--mentor {
        margin-top: 5vh;
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gymi_support > div.chats > div.topic--mentor span {
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 2rem;
        color: rgb(50,50,50);
        opacity: 0.5;
    }
    .gymi_support > div.chats > div.mentor {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        direction: ltr;
        overflow-x: hidden;
        overflow-y: auto;
        gap: 1vh;
        padding-top: 2vh;
        padding-bottom: 2vh;
    }
    .gymi_support > div.chats > div.mentor > div.row {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        direction: ltr;
        align-items: center;
    }
    .gymi_support > div.chats > div.mentor > div.row > div.chat {
        /* width: 40%;
        height: 100%;
        margin-left: 5%;
        margin-right: 1.6%; */
        width: 45%;
        height: 100%;
        margin-left: 2%;
        margin-right: 1.6%;
        background-color: black;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        border-radius: 2rem;
        border: solid rgb(50,50,50) 0.1rem;

        cursor: pointer;
        transition: all 0.3s;
    }
    .gymi_support > div.chats > div.mentor > div.row > div.chat.invite {
        border-color: black;
        color: rgb(185,55,20);
        font-size: 3rem;
    }
    .gymi_support > div.chats > div.mentor  > div.row > div.chat.invite:hover , div.chat.invite:active {
        border-color: rgb(0,120,0);
        color: rgb(0,120,0);
    }
    .gymi_support > div.chats > div.mentor  > div.row > div.chat img {
        height: 40%;
        object-fit: cover;
        opacity: 0.1;
        transition: all 0.3s;
    }
    .gymi_support > div.chats > div.mentor  > div.row > div.chat > p.chat--name {
        width: 100%;
        height: 30%;
        width: fit-content;
        color: rgb(100,100,100);
        text-align: center;
        margin: 1%;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1rem;

        transition: all 0.3s;
    }
    

    .chat--modal {
        top: 0;
        left: 0;
        display: none;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgb(200,200,200); /* Fallback color */
        background-color: rgba(200,200,200,0.8); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(20px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }
    .chat--modal > div.modal--chatbox {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: aliceblue;
        display: flex;
        justify-content:flex-start;
        /* align-items: center; */
        opacity: 0;
        overflow: none;
        transition: all 0.3s;
    }
}
@media (orientation: landscape) {
    .agreement {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        display: flex;
        opacity: 0;
        flex-direction: row;
        flex-wrap: wrap;
        direction: rtl;
        justify-content: center;
        backdrop-filter: blur(20px);
        z-index: 10003;

        transition: all 0.5s;
    }

    .agreement.fade-in {
        opacity: 1; /* Fully visible */
    }

    .agreement.fade-out {
        opacity: 0; /* Fully hidden */
    }

    .agreement > span.text {
        width: 100%;
        height: 20%;
        margin-top: 20%;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 2rem;
        color: white;
    }

    .agreement > button.agree {
        width: 40%;
        margin-top: 10%;
        height: 10%;
        margin-right: 5%;
        margin-left: 5%;
        text-align: center;
        border-radius: 1rem;
        border: solid rgb(255, 255, 255) 0.1rem;
        background-color: black;
        font-size: 1.5rem; 
        color: white;
        cursor: pointer;

        transition: all 0.5s;
    }

    .agreement > button.agree:hover , button.agree:active {
        color: rgb(0,120,0);
        border-color: rgb(0,30,0);
    }

    .agreement > button.back {
        width: 40%;
        margin-top: 10%;
        height: 10%;
        margin-right: 5%;
        margin-left: 5%;
        text-align: center;
        border-radius: 1rem;
        border: solid rgb(255, 255, 255) 0.1rem;
        background-color: black;
        font-size: 1.5rem;
        color: white;
        cursor: pointer;

        transition: all 0.5s;
    }

    .agreement > button.back:hover , button.back:active {
        color: rgb(185,55,20);
        border-color: rgb(30,0,0);
    }

}


@media (orientation: portrait) {
    .agreement {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        display: flex;
        opacity: 0;
        flex-direction: row;
        flex-wrap: wrap;
        direction: rtl;
        justify-content: center;
        backdrop-filter: blur(20px);
        z-index: 10002;
        transition: all 0.5s;
    }

    .agreement.fade-in {
        opacity: 1; /* Fully visible */
    }

    .agreement.fade-out {
        opacity: 0; /* Fully hidden */
    }

    .agreement > span.text {
        width: 80%;
        height: 20%;
        margin-top: 35%;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.5rem;
        color: rgba(52, 152, 219, 1);
        background-color: rgba(52, 152, 219, 0.1);
        border-radius: 1rem;
    }

    .agreement > button.agree {
        width: 35%;
        margin-top: 10%;
        height: 15%;
        margin-right: 3%;
        margin-left: 2%;
        text-align: center;
        border-radius: 1rem;
        border: none;
        background-color: rgb(100,100,100);
        font-size: 1.2rem; 
        color: white;
        cursor: pointer;

        transition: all 0.5s;
    }

    .agreement > button.agree:hover , button.agree:active {
        color: rgb(0,100,0);
    }

    .agreement > button.back {
        width: 35%;
        margin-top: 10%;
        height: 15%;
        margin-right: 2%;
        margin-left: 3%;
        text-align: center;
        border-radius: 1rem;
        border: none;
        background-color: rgb(100,100,100);
        font-size: 1.2rem;
        color: white;
        cursor: pointer;

        transition: all 0.5s;
    }

    .agreement > button.back:hover , button.back:active {
        color: rgb(185,55,20);
    }
}

@media (orientation: portrait) {
    div.requests_page {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        background-color: rgb(200, 200, 200);
    }

    div.requests_page > div.controlbox {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    div.requests_page > div.controlbox > button.back {
        height: 45px;
        width: 15%;
        background-color: rgb(0,0,0);
        border: solid 0.1rem black;
        color: white;
        font-size: 1.5rem;
        border-radius: 1rem;
        transition: all 0.3s;
    }
    div.requests_page > div.controlbox > button.back:hover , button.back:active{
        background-color: white;
        color: rgb(180,0,0);
        border-color: rgb(180,0,0);
    }
    div.requests_page > div.controlbox > div.topic {
        width: 70%;
        height: 45px;
        background-color: rgb(0,0,0);
        border: solid 0.1rem rgb(185, 55, 20);
        color: rgb(120,120,120);
        font-size: 1.5rem;
        border-radius: 1rem;
    }
    div.requests_page > div.controlbox > div.topic span {
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
    }

    div.requests_page > div.pending {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow-x: none;
        overflow-y: auto;
        align-items: center;
    }

    div.requests_page > div.pending > div.request {
        width: 90%;
        height: 20%;
        background-color: rgb(20,20,20);
        border: solid 0.1rem rgb(160,160,160);
        border-radius: 1rem;
        margin-top: 20%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        direction: rtl;
    }

    div.requests_page > div.pending > div.request > div.details {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }   

    div.requests_page > div.pending > div.request > div.details p {
        width: 100%;
        height: 30%;
        color: white;
        text-align: center;
        margin-top: 20px;
    }  

    div.requests_page > div.pending > div.request > div.controlbox{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }  

    div.requests_page > div.pending > div.request > div.controlbox button{
        width: 40%;
        height: 60%;
        border:  0.1rem black;
        border-style: groove;
        border-radius: 1rem;
        color: white;
        font-size: 1rem;
        background-color: rgb(30,30,30);
        transition: all 0.3s;
    }  

    div.requests_page > div.pending > div.request > div.controlbox button.accept{
        border-color: rgb(0,120,0);
    } 
    div.requests_page > div.pending > div.request > div.controlbox button.reject{
        border-color: rgb(120,0,0);
    } 

    div.requests_page > div.pending > div.request > div.controlbox button:hover , button:active{
        background-color: rgb(0,0,0);
    }
}

@media (orientation: portrait) {

    .cpanel--body {
        width: 100%;
        height: 100%;
        background-color: rgb(240,245,240);
        margin: 0;
        position: fixed;
    }

    .cpanel--body > div {
        width: 100%;
        height: 100%;
        background-color: rgb(240,245,240);
        margin: 0;
        position: fixed;
        display: flex;
        flex-direction: column;
    }

    .cpanel--dashboard {
        margin-top: 2%;
        margin-left: 2%;
        width: 96%;
        height: 8%;
        background-color: white;
        border-bottom-style:solid;
        border-color: black;
        border-width: 1px;
        border-radius: 15px;

        display: flex;
        flex-direction: row;
        position: relative;
        left: 0;

        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
        transition: left 1000ms;
    }

    .cpanel--viewboard {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        left: 0;
        overflow-y: auto;

        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out;
        transition: left 1000ms;
    }

    .cpanel--dashboard > a.logo {
        width: 20%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
    }

    .cpanel--dashboard > a.logo img {
        width: 25px;
        height: 25px;
    }

    .cpanel--dashboard > a.logo img:hover , img:active {
        filter: brightness(40%);

    }

    .cpanel--dashboard > div.task  {
        width: 16%;
        height: 100%;

        border-right-style:solid;
        border-color: black;
        border-width: 1px;
        justify-content: center;
        align-items: center;
        display: flex;
        transition: color 0.2s;
        transition: background-color 0.2s;
        mix-blend-mode: multiply;
    }

    .cpanel--dashboard > div.task.mealmaker  {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    .cpanel--dashboard > div.task:hover , div.task:active  {
        cursor: pointer;
    }

    .cpanel--dashboard > div.task > img  {
        width: 20px;
        height: 20px;
        opacity: 0.9;
        /* color: orange; */
        /* background-color: orange; */
    }

    .cpanel--dashboard > div.task.meals > img  {
        margin-top: 5px;
        /* color: orange; */
        /* background-color: orange; */
    }


    .gymi {
        position: absolute;
        left: 1%;
        right: 1%;
        height: 98%;
        display: inline-block;

        /* border-style: solid;
        border-width: 1vw;
        border-color: rgb(185, 55, 20); */
        border-radius: 15px;
        overflow-x: hidden;
        flex-wrap: wrap;
        overflow-y: auto;

        background-color: rgb(250,250,250);
    }


    .gymi > span.topic {
        position: absolute;
        top: calc(10% - 20px);
        left: 10%;
        right: 10%;
        text-align: center;
        font-size: 2.5rem;
        font-weight: bold;
        color: rgb(185,55,20,0.7);
    }

    .gymi > div.gymi--models {
        position: absolute;
        width: 200px;
        left:calc((100% - 200px) / 2);
        top: calc(30% + 20px);
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-left: solid 1px rgb(185,55,20);
        border-right: solid 1px rgb(185,55,20);
    }

    .gymi > div.gymi--models > div.gymi--model {
        position: relative;
        width: 30px;
        height: 80px;
        display: inline-block;
        border-bottom: solid 0.1rem rgb(0,120,0,0.2);
        cursor: pointer;
        transition: all 0.3s;
        touch-action: manipulation;
        user-select: none;
    }

    .gymi > div.gymi--models > div.gymi--model.zero:hover , div.gymi--model.zero:active {
        color: rgb(185,55,20) !important;
    }

    .gymi > div.gymi--models > div.gymi--model > div.mentor--models {
        position: absolute;
        top: 0;
        left: -85px;
        width: 200px;
        height: 240px;
        display: inline-block;
        background-color: rgb(200,200,200,0.1);
        box-shadow: 0px 0px 20px 2px rgb(0,80,0,0.3);
        /*border-bottom:solid 0.1rem rgb(100,100,100,0.5);*/
    }

    .gymi > div.gymi--models > div.gymi--model > div.back {
        position: absolute;
        top: 210px;
        height: 30px;
        left: -85px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        touch-action: manipulation;
        user-select: none;
    }

    .gymi > div.gymi--models > div.gymi--model > div.back > img {
        width: 25px;
        height: 25px;
    }

    .gymi > div.gymi--models > div.gymi--model > div.mentor--models > span.topic {
        position: absolute;
        top: 5px;
        height: 20px;
        width: 100%;
        color: rgb(0,0,0,0.5);
        font-size: 1.2rem;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        touch-action: manipulation;
        user-select: none;
    }

    .gymi > div.gymi--models > div.gymi--model > div.mentor--models > div.mode {
        position: absolute;
        width: 50%;
        top: 50px;
        height: 40px;
        color: rgb(185,55,20,0.7);
        font-size: 1rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        /*font-weight: bold;*/
        touch-action: manipulation;
        user-select: none;
    }

    .gymi > div.gymi--models > div.gymi--model > div.mentor--models > div.mode.diet {
        left:0;
        border-right: solid 1px rgb(0,120,0,0.2);
    }
    .gymi > div.gymi--models > div.gymi--model > div.mentor--models > div.mode.workout {
        right:0;
        border-left: solid 1px rgb(0,120,0,0.2);
    }


    .gymi > div.gymi--models > div.gymi--model > span {
        position: absolute;
        right: -35px;
        width: 100px;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 1rem;
    }

    .gymi > div.mymodal  > div.guide--chat {
        position: absolute;
        left: 5%;
        right: 5%;
        top: 10%;
        bottom: 5%;
        border-radius: 1rem;
        box-shadow: 0px 0px 20px 2px rgb(120,120,0,0.7);
        /*padding: 5px;*/
    }
    .gymi > div.mymodal  > div.guide--chat > div.chatbox {
        border-radius: 1rem;
    }


    .cpanel--viewboard > .plans {
        position: absolute;
        left: 1%;
        right: 1%;
        height: 98%;
        display: flex;
        flex-direction: row;
        justify-content: left;

        /* border-style: solid;
        border-width: 1vw;
        border-color: rgb(185, 55, 20); */
        border-radius: 15px;
        overflow-x: hidden;
        flex-wrap: wrap;
        overflow-y: auto;

        background-color: rgb(250,250,250);
    }
    .cpanel--viewboard > .plans > span.topic.t0 {
        position: absolute;
        top: calc(10% - 20px);
        left: 10%;
        right: 10%;
        text-align: center;
        font-size: 2.5rem;
        font-weight: bold;
        color: rgb(185,55,20,0.7);
    }
    .cpanel--viewboard > .plans > span.topic.t1 {
        position: absolute;
        top: calc(10% + 30px);
        left: 10%;
        right: 10%;
        text-align: center;
        font-size: 0.8rem;
        font-weight: bold;
        color: rgb(185,55,20,0.7);
    }
    .cpanel--viewboard > .plans > div.plans--models {
        position: absolute;
        top: calc(50% - 40px);
        height: 80px;
        width: 200px;
        left: calc(50% - 100px);
        border-top:solid 1px rgb(185,55,20);
        border-bottom:solid 1px rgb(185,55,20);
        display: inline-block;
    }
    .cpanel--viewboard > .plans > div.plans--models > div.mode {
        position: absolute;
        top: 15%;
        width: 50%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
    }
    .cpanel--viewboard > .plans > div.plans--models > div.mode > span {
        text-align: center;
        font-size: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    .cpanel--viewboard > .plans > div.plans--models > div.mode.diet {
        left: 0;
        border-right: solid 1px rgb(0,120,0,0.2);
    }
    .cpanel--viewboard > .plans > div.plans--models > div.mode.workout {
        right: 0;
        border-left: solid  1px rgb(0,120,0,0.2);
    }

    .cpanel--viewboard > .plans > div.mode.diet:hover , div.mode.diet:active {
        border-color: black;
        background-color: rgb(0,0,0);
        color: white;
    }
    .cpanel--viewboard > .plans > div.mode.workout:hover , div.mode.workout:active {
        border-color: black;
        background-color: rgb(0,0,0);
        color: white;
    }





    .cpanel--viewboard > .trainees {
        position: absolute;
        left: 1%;
        right: 1%;
        height: 98%;
        display: flex;
        flex-direction: row;
        justify-content: left;

        /* border-style: solid;
        border-width: 1vw;
        border-color: rgb(185, 55, 20); */
        border-radius: 15px;
        overflow-x: hidden;
        flex-wrap: wrap;
        overflow-y: auto;

        background-color: rgb(250,250,250);
    }

    .cpanel--viewboard > .trainees > span.topic {
        position: absolute;
        top: calc(10% - 20px);
        left: 10%;
        right: 10%;
        text-align: center;
        font-size: 2.5rem;
        font-weight: bold;
        color: rgb(185,55,20,0.7);
    }

    .cpanel--viewboard > .trainees > div.mode {
        position: absolute;
        transition: all 0.5s;
        display: inline-block;
        cursor: pointer;
    }

    .cpanel--viewboard > .trainees > div.mode img {
        position: absolute;
        left: calc((100% - 90px) / 2);
        top: calc((100% - 90px) / 2);
        width: 90px;
        height: 90px;
        opacity: 0.1;
    }

    .cpanel--viewboard > .trainees > div.mode span {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;

    }

    .cpanel--viewboard > .trainees > div.mode.requests {
        left: 10%;
        right: 10%;
        height: 100px;
        border-radius: 2rem;
        background-color: rgb(240,230,230);
        color: rgb(185,55,20,0.9);
        box-shadow: 0px 0px 20px 2px rgb(180,180,180);
        top: 30%;
    }

    .cpanel--viewboard > .trainees > div.mode.mytrainees {
        top: calc(30% + 100px + 40px);
        right: 10%;
        left: 10%;
        height: 100px;
        border-radius: 2rem;
        background-color: rgb(240,230,230);
        color: rgb(185,55,20,0.9);
        box-shadow: 0px 0px 20px 2px rgb(180,180,180);
        /* border: solid 0.1rem black; */
        touch-action: manipulation;
        user-select: none;
    }

    .cpanel--viewboard > .trainees > div.mode.requests:hover , div.mode.requests:active {
        border-color: black;
        background-color: rgb(0,0,0);
        color: white;
    }
    .cpanel--viewboard > .trainees > div.mode.mytrainees:hover , div.mode.mytrainees:active {
        border-color: black;
        background-color: rgb(0,0,0);
        color: white;
    }




    /*  */

   .commingsoon {
        background-color: black;
        color: white;
        width: 40%;
        height: 20%;
        position: absolute;
        left: 30%;
        top: 40%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .commingsoon span {
        font-size: 4vh;
        text-align: center;
        width: 100%;
        direction: rtl;

    }



}
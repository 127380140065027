@media (orientation: landscape) {
    .login {
        width: 100%;
        height: 100%;
        background-color: rgb(50,50,50);
        margin: 0;
        position: absolute;
        overflow: hidden;
        display: flex;
    }

    .login input:focus {
        outline: none;
    }
    
    .login > div.form {
        top: 15%;
        left: 0%;
        width: 100%;
        height: 85%;
        position: relative;
        display: inline-block;
        background-color: rgb(10, 10, 10);
        direction: rtl;
    }

    .login > div.form > div.form--topic {
        width: 50%;
        height: 15%;
        top: 10%;
        left: 25%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: solid 2px rgb(185,55,20);
        border-radius: 10px;
    }

    .login > div.form > div.form--topic > span.login--topic {
        font-size: 5vh;
        width: 100%;
        color: white;
        text-align: center;
    }

    .login > div.form input {
        background-color: rgb(40,40,40);
        border: solid 2px black;
        color: rgb(200,200,200);
        font-size: 3vh;
        border-radius: 5px;
        height: 8%;
        text-align: center;
        position: absolute;
        direction: ltr;
    }

    .login > div.form input.username {
        left: 20%;
        right: 20%;
        top:35%;
    }

    .login > div.form input.password {
        left: 20%;
        right: 20%;
        top:45%;
    }

    .login > div.form button.login--button {
        width: 40%;
        height: 10%;
        background-color: rgb(10,10,10);
        color: white;
        border-color: rgb(0,60,0);
        border-style: solid;
        border-width: 2px;
        position: absolute;
        top:63%;
        right: 30%;
        font-size: 4vh;
        border-radius: 10px;

        transition: all 0.3s;
    }

    .login > div.form button.login--button:hover {
        background-color: black;
        border-color: rgb(0,180,0);
    }

    .login > div.form > a.loginform--signup {
        width: 10%;
        height: 20%;
        height: fit-content;
        font-size: 2.5vh;
        background: none;
        /* background-color: black; */
        color: rgb(250, 230, 210);
        position: absolute;
        top: 80%;
        text-align: center;
        right: 35%;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.5;

        transition: all 0.5s;
    }

    .login > div.form > a.loginform--forgot {
        width: 10%;
        height: 20%;
        height: fit-content;
        font-size: 2.5vh;
        background: none;
        /* background-color: black; */
        color: rgb(250, 230, 210);
        position: absolute;
        top: 80%;
        text-align: center;
        left: 35%;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
        opacity: 0.5;

        transition: all 0.5s;
    }

    .login > div.form > a.loginform--signup:hover , a.loginform--signup:active{
        font-size: 1.6vh;
        color: rgb(185,55,20);
        opacity: 1;
    }

    .login > div.form > a.loginform--forgot:hover , a.loginform--forgot:active{
        font-size: 1.6vh;
        color: rgb(185,55,20);
        opacity: 1;
    }

    .login > div.form > span.log--error {
        width: 80%;
        height: fit-content;
        font-size: 1.5vh;
        background: none;
        position: absolute;
        top: 85%;
        text-align: center;
        right: 10%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
}
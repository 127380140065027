
div.toggleswitch {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
}

div.toggleswitch > span.dialog {
    position: absolute;
    top: calc((100% - 25px) / 2);
    width: 70%;
    height: 25px;
    right: 5px;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;
}

div.toggleswitch > div.toggle {
    position: absolute;
    top: calc((100% - 25px) / 2);
    left: 5px;
    width: 45px;
    height: 25px;
    border-radius: 1rem;
    transition: all 0.2s;
}

div.toggleswitch > div.toggle > div.slider {
    position: absolute;
    left: 2px;
    top: 2.5px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    transition: all 0.3s;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px 2px rgb(20,55,185);
    
}

div.toggleswitch > div.toggle > div.slider > img {
    width: 15px;
    height: 15px;
    opacity: 0.6;
    transition: all 0.2s;
    
    /* border-radius: 50%; */
}
@media (orientation: portrait) {
    .chatbox {
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 0px;
        direction: rtl;
        white-space: normal;
        word-wrap: break-word;
        /* border-radius: 1rem; */
        /* border: solid 0.1rem rgb(185, 55, 20); */
        overflow-y: hidden;
    }

    .chatbox > div.static--chats > div.chatbox--input {
        width: 100%;
        height: auto;
        max-height: 210px;
        min-height: 60px;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        background-color: white;
    }

    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        max-height: 215px;
        min-height: 55px;
        overflow: hidden;
        background-color: white;
    }

    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin textarea {
        position: absolute;
        top: 0;
        bottom: 10px;
        left: 3%;
        right: 15%;
        height: auto;
        max-height: 200px;
        min-height: 45px;
        overflow: hidden;
        /* position: absolute; */
        /* bottom: 0;
        left: 0; */
        /* top: 0; */
        padding: 15px;
        box-sizing:border-box;
        font-size: 0.9rem;
        background-color: rgb(230,230,230);
        color: black;
        border-radius: 1.5rem;
        border: none;
        direction: ltr;
    }

    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin textarea:focus {
        outline: none;
    }

    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin button {
        position: absolute;
        top: 0;
        right: 1%;
        left: 87%;
        height: 40px;
        background-color: rgb(220,220,220);
        border-radius: 3rem;
        color: black;
        border: none;
        opacity: 0.3;

        transition: all 0.3s;
    }

    .chatbox > div.static--chats > div.chatbox--input > div.inputbox--margin img {
        height: 20px;
        width: 20px;
    }

    .chatbox > div.controlbox {
        width: 100%;
        height: 11%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background-color: white;
    }

    .chatbox > div.controlbox button {
        width: 25%;
        height: 70%;
        border: none;
        border-radius: 1rem;
        background-color: rgb(220,220,220);
        opacity: 0.8;
        font-size: 0.9rem;

        transition: all 0.3s;
    }

    .chatbox > div.controlbox button.controlbox:active , button.controlbox:hover {
        opacity: 1;
    }

    .chatbox > div.static--chats > div.streambox {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: right;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }

    .chatbox > div.static--chats > div.streambox > div.textbox {
        margin:5px;
        padding: 5px;
        position: relative;
        width: 85%;
        min-height: 2%;
        height: fit-content;
        display: flex;
        justify-content: right;
        border-radius: 25px;
        align-items: center;
        /* background-color: rgb(30 , 30 , 30); */

        transition: all 0.2s;
    }
    .chatbox > div.static--chats > div.streambox > div.textbox span {
        margin: 5px;
        position: relative;
        /* color: white; */
        color: black;
        height: fit-content;
        min-width: 10%;
        max-width: 100%;

        white-space: pre-line;
        word-wrap: break-word;
        font-size: clamp(0.7rem , 2vh , 1.2rem);
        line-height: 2.5vh;
        min-height: 3vh;
        align-content: center;
    }
    .chatbox > div.static--chats > div.streambox > div.logobox {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .chatbox > div.static--chats > div.streambox > div.logobox .logo {
        height: 25px;
        width: 25px;
        border-radius: 12px;
        border: solid 0.1rem rgb(240,240,240);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .chatbox > div.static--chats > div.streambox > div.logobox .logo img {
        width: 12px;
        height: 12px;
    }

    .chatbox > div.static--chats {
        overflow-y: auto;
        height: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;

        transition: all 0.2s;
    }
    .chatbox > div.static--chats > :first-child {
        margin-top: auto !important;
    }

    .chatbox > div.static--chats > div.leftchat {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: left;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .chatbox > div.static--chats > div.leftchat > div.textbox {
        margin:5px;
        padding: 10px;
        position: relative;
        min-width: 20px;
        max-width: 85%;
        min-height: 2%;
        height: fit-content;
        display: flex;
        justify-content: right;
        border-radius: 20px;
        /* border-color: white;
        border-width: 2px;
        border-style: solid; */
        /* background-color: rgb(210 , 210 , 210); */
        background-color: rgb(230,230,230);

        transition: all 0.2s;
    }
    .chatbox > div.static--chats > div.leftchat span {
        margin: 5px;
        position: relative;
        /* color: white; */
        color: black;
        height: fit-content;

        white-space: pre-line;
        word-wrap: break-word;
        font-size: clamp(0.7rem , 2vh , 1.2rem);
        line-height: 2.5vh;
        min-height: 3vh;
        text-align: right;
    }

    .chatbox > div.static--chats > div.rightchat {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: right;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .chatbox > div.static--chats > div.rightchat > div.textbox {
        margin:5px;
        padding: 5px;
        position: relative;
        width: 85%;
        min-height: 2%;
        height: fit-content;
        display: flex;
        justify-content: right;
        border-radius: 25px;
        align-items: center;
        /* border-color: rgb(185, 55, 20);
        border-width: 2px;
        border-style: solid; */
        /* background-color: rgb(230,230,230); */

        transition: all 0.2s;
    }
    .chatbox > div.static--chats > div.rightchat > div.textbox span {
        margin: 0 5px 0 5px;
        position: relative;
        color: #000000;
        height: fit-content;
        min-width: 10%;
        max-width: 100%;
        width: auto;
        white-space: pre-line;
        word-wrap: break-word;
        font-size: clamp(0.7rem , 2vh , 1.2rem);
        line-height: 2.5vh;
        min-height: 3vh;
        align-content: center;
    }
    .chatbox > div.static--chats > div.rightchat > div.logobox {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .chatbox > div.static--chats > div.rightchat > div.logobox .logo {
        height: 25px;
        width: 25px;
        border-radius: 12px;
        border: solid 0.1rem rgb(240,240,240);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .chatbox > div.static--chats > div.rightchat > div.logobox .logo img {
        width: 12px;
        height: 12px;
    }




    .chatbox > div.mymodal > div.plannermodal {
        position: absolute;
        width: 100%;
        height: 100%;
        display: inline-block;
    }
    .chatbox > div.mymodal > div.plannermodal > span.topic {
        position: absolute;
        top: 25%;
        height: 50px;
        width: 100%;
        color: black;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 1.5rem;
        text-align: center;
        box-shadow: 0px 0px 20px rgb(150,180,150);
    }
    .chatbox > div.mymodal > div.plannermodal > input.nameinput {
        position: absolute;
        top: calc(40% + 20px);
        left: 10%;
        right: 10%;
        height: 50px;
        color: rgb(200,200,200);
        background-color: rgb(30,30,30);
        border: none;
        outline: none;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.2rem;
        box-shadow: 0px 0px 10px rgb(185,55,20);
    }
    .chatbox > div.mymodal > div.plannermodal  > button.send {
        position: absolute;
        top: calc(40% + 100px);
        right: 10%;
        width: 35%;
        height: 45px;
        background-color: rgb(30,30,30,0.8);
        text-align: center;
        font-size: 1rem;
        border-radius: 1rem;
        border: 0.1rem solid black;
        color: white;
        transition: all 0.3s;
    }
    .chatbox > div.mymodal > div.plannermodal > button.back {
        position: absolute;
        top: calc(40% + 100px);
        left: 10%;
        width: 35%;
        height: 45px;
        background-color: rgb(30,30,30,0.8);
        text-align: center;
        font-size: 1rem;
        border-radius: 1rem;
        border: 0.1rem solid black;
        color: white;
        transition: all 0.3s;
    }
    .chatbox > div.mymodal > div.plannermodal > button.send:hover , button.send:active {
        border: 0.1rem solid rgb(0,120,0);
    }
    .chatbox > div.mymodal > div.plannermodal > button.back:hover , button.back:active {
        border: 0.1rem solid rgb(120,0,0);
    }





    .chatbox > div.delconv--modal {
        left: 0;
        top: 0;
        display: none;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(30,30,30,0.7); /* Black w/ opacity */
        color: white;
        backdrop-filter: blur(10px);
        position: fixed; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        animation: fadeInOut 1s ease-in-out;
        transition: all 0.3s;
    }

    .chatbox > div.delconv--modal > div.modal--delverify {
        position: absolute;
        width: 100%;
        height: 100%;
        display: inline-block;
        opacity: 0;
        overflow: none;
        transition: all 0.3s;
    }

    .chatbox > div.delconv--modal > div.modal--delverify > div.topic {
        position: absolute;
        top: 30%;
        left: 0;
        right: 0;
        height: 10%;
        color: white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 1.2rem;
    }

    .chatbox > div.delconv--modal > div.modal--delverify > button.agree {
        position: absolute;
        top: 45%;
        right: 10%;
        width: 35%;
        height: 40px;
        background-color: rgb(30,30,30);
        text-align: center;
        font-size: 1rem;
        border-radius: 1rem;
        border: none;
        color: white;
    }

    .chatbox > div.delconv--modal > div.modal--delverify > button.disagree {
        position: absolute;
        top: 45%;
        left: 10%;
        width: 35%;
        height: 40px;
        background-color: rgb(30,30,30);
        text-align: center;
        font-size: 1rem;
        border-radius: 1rem;
        border: none;
        color: white;
    }
}
@media (orientation: landscape) {
    div.home {
        width: 100%;
        height: 100%;
        background-color: rgb(50,50,50);
        margin: 0;
        position: absolute;
        overflow: hidden;
    }

    div.home > div.boxBanners {
        height: 75%;
        width: 100%;
        top: 20%;
        position: absolute;
    }
    div.home > div.boxBanners > img.banner {
        width: 96%;
        left: 2%;
        max-height: 100%;
        position: absolute;
        border-radius: 20px;
        border: solid 2px black;

        transition: all 1s;
    }
    div.home > div.boxBanners > span.sliderLeft {
        height: 20%;
        text-align: center;
        align-items: center;
        position: absolute;
        display: flex;
        font-size: 8vh;
        color: rgb(50, 50, 50);
        top: 42.5%;
        left: 3%;
        opacity: 0.6 transparent;
        cursor: pointer;

        transition: all 0.5s;
    }
    div.home > div.boxBanners > span.sliderRight {
        height: 20%;
        text-align: center;
        align-items: center;
        display: flex;
        position: absolute;
        font-size: 8vh;
        color: rgb(50, 50, 50);
        /* text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff; */
        /* -webkit-text-stroke: 1px rgb(185, 55, 20); */
        top: 42.5%;
        right: 3%;
        opacity: 0.6;
        cursor: pointer;

        transition: all 0.5s;
    }
    div.home > div.boxBanners > span.sliderLeft:hover {
        color: rgb(0,30,0);
    }
    div.home > div.boxBanners > span.sliderRight:hover {
        color: rgb(0,30,0);
    }
}

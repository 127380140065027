@media (orientation: portrait) {
    div.trainee_desk_chat {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

}